import ModalLayout from '@components/layouts/ModalLayout';
import { Modal } from '@mui/material';
import { useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { CommonButton } from '@components/common/CommonButton';
import { useCreateReportMutation } from '@api/reportsSlice';
import { useDispatch } from 'react-redux';
import { FormItemInterface } from '@interface/dynamicFormInterface';
import { formatScamEnablers } from '../CreateReportPage/CreateReportComponent';
import { showAlert } from '@store/alertSlice';
import CreateReportScamEnablers from '../CreateReportPage/CreateReportScamEnablers';

export const formatEditedForm = (formData: any, report: any, user: any) => {
  let params: any = {};
  params.report = {
    reportNumber: report.reportNumber,
    createdBy: report.createdBy,
    classification: report.classification,
    spfEmail: user.email,
    policeStation: 'CAD / ASCOM',
    scamType: report.scamType,
    purposeOfSubmission: 'Adding to the previous submission (new scam enablers found)',
  };
  params.scamEnablers = formatScamEnablers(formData.scamEnablers);
  // console.log('params', params);

  return params;
};

export default function AddScamEnablersModal({
  isOpen,
  setIsOpen,
  reportId,
  report,
  currentUser,
  callback,
}: {
  isOpen: boolean;
  setIsOpen: any;
  reportId: string;
  report: any;
  currentUser: any;
  callback: any;
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [scamEnablerFormState, setScamEnablerFormState] = useState<Array<Array<FormItemInterface>>>(
    [],
  );
  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currScamEnabler, setCurrScamEnabler] = useState<number>(0);
  const [createReport] = useCreateReportMutation();
  const dispatch = useDispatch();

  const formMethods = useForm({
    values: {
      scamEnablers: [{ useOfScamEnabler: '', platform: '', type: 'Moniker' }],
    },
    mode: 'onChange',
  });

  // const scamEnablerType: FormItemInterface = {
  //   label: 'Type',
  //   type: 'boolean',
  //   id: 'type',
  //   alignment: 'column',
  //   rules: {
  //     required: 'Please select an option',
  //   },
  //   extras: {
  //     radioItems: [
  //       {
  //         value: 'Moniker',
  //         label: 'Moniker',
  //         infoText: 'Monikers include:',
  //         infoBulletPoints: [
  //           'Facebook',
  //           'Instagram',
  //           'Shopee',
  //           'Lazada',
  //           'Carousell',
  //           'X',
  //           'Whatsapp',
  //           'Telegram',
  //         ],
  //       },
  //       {
  //         value: 'Payment',
  //         label: 'Payment',
  //         infoText: 'Payment Channels include:',
  //         infoBulletPoints: ['Banks', 'PayNow', 'PayLah'],
  //       },
  //     ],
  //   },
  // };

  const { fields, append, remove, update } = useFieldArray({
    control: formMethods.control,
    name: 'scamEnablers',
  });

  const handleCloseDeleteModal = () => {
    setCurrScamEnabler(0);
    setShowDeleteModal(false);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    let values = formMethods.getValues();
    let params = formatEditedForm(values, report, currentUser);
    let response: any = await createReport(params);
    if (!response.error) {
      dispatch(
        showAlert({
          type: 'success',
          message: 'Scam enabler added to report successfully.',
        }),
      );
      await callback();
    }
    setIsSubmitting(false);
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <ModalLayout
        title={'Add Scam Enablers'}
        handleCloseModal={() => {
          setIsOpen(false);
        }}
        alignment={'center'}
        size={'medium'}
      >
        <CreateReportScamEnablers
          formMethods={formMethods}
          scamEnablerFormState={scamEnablerFormState}
          setScamEnablerFormState={setScamEnablerFormState}
          isShowPaymentChannel={true}
          isFormCreateReport={false}
        />
        <div className="flex gap-4 mr-0 ml-auto mt-4">
          <CommonButton
            variant={'text'}
            title={'Cancel'}
            onClick={() => {
              setIsOpen(false);
            }}
          />
          <CommonButton
            variant={'primary'}
            title={'Add'}
            onClick={handleSubmit}
            isLoading={isSubmitting}
          />
        </div>
      </ModalLayout>
    </Modal>
  );
}
