import { FormItemInterface } from '@interface/dynamicFormInterface';
import { getField, processData } from './DynamicForm';
import { useFieldArray } from 'react-hook-form';
import { CommonButton, IconButton } from './CommonButton';
import cloneDeep from 'lodash.clonedeep';
import { useEffect } from 'react';

const DynamicTable = ({
  tableId,
  formFields,
  data,
  children,
  formMethods,
  setBadgeText,
  callback,
  isEditable,
  isLoading,
  parentExtras,
  formClass,
  defaultRow,
}: {
  tableId: string;
  formFields: Array<FormItemInterface>;
  formMethods?: any;
  data?: any;
  children?: any;
  setBadgeText?: any;
  callback?: any;
  isEditable?: boolean;
  isLoading?: boolean;
  parentExtras?: any;
  formClass?: any;
  defaultRow?: any;
}) => {
  const processedForm = processData(formFields, data);
  const processField = (field: FormItemInterface, index: number, data: any) => {
    let newField = cloneDeep(field);
    newField.id = `${tableId}[${index}][${field.id}]`;
    if (newField?.extras?.idCurrency) {
      newField.extras.idCurrency = `${tableId}[${index}][${field.extras.idCurrency}]`;
    }
    if (
      !isEditable &&
      field.id &&
      data &&
      data[tableId] &&
      data[tableId][index] &&
      data[tableId][index][field.id]
    ) {
      newField.value = data[tableId][index][field.id];
    }
    return newField;
  };
  const { fields, append, remove } = useFieldArray({
    control: formMethods.control,
    name: tableId,
  });

  useEffect(() => {
    if (fields.length === 0 && isEditable) {
      append(defaultRow);
    }
  }, [fields, isEditable]);

  return (
    <>
      {processedForm && (
        <div className="mt-5 relative overflow-x-auto rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right">
            <thead>
              <tr className="bg-gray-200 font-bold">
                {processedForm &&
                  processedForm.length > 0 &&
                  processedForm.map((field: FormItemInterface, index: number) => (
                    <td className="min-w-40 p-2" key={index}>
                      {field.label}
                    </td>
                  ))}
              </tr>
            </thead>

            <tbody>
              {fields &&
                processedForm &&
                fields.map((arrayField: any, index) => (
                  <tr key={arrayField.id}>
                    {processedForm.map((field) => (
                      <td
                        className={`min-w-40 p-2 bg-${index % 2 ? 'gray-100' : 'white'}`}
                        key={field.id}
                      >
                        {!isLoading &&
                          formMethods &&
                          getField(
                            processField(field, index, data),
                            arrayField[field.id],
                            callback,
                            isEditable,
                            formMethods,
                            setBadgeText,
                            parentExtras,
                          )}
                      </td>
                    ))}

                    {isEditable && (
                      <IconButton
                        icon="delete"
                        isDisabled={false}
                        onClick={() => {
                          remove(index);
                        }}
                        className="mt-4"
                      />
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
          {isEditable && (
            <CommonButton
              title="Add another row"
              isDisabled={false}
              onClick={(e: any) => {
                e.preventDefault();
                append(defaultRow);
              }}
              variant="text"
              className="mt-4"
            />
          )}
        </div>
      )}
    </>
  );
};

export default DynamicTable;
