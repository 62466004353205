import { CommonButton } from '@components/common/CommonButton';
import FileUpload from '@components/common/FileUpload';
import ModalLayout from '@components/layouts/ModalLayout';
import { Badge, Modal, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import csv from 'csvtojson';
import cloneDeep from 'lodash.clonedeep';
import { platform, scamType } from '@interface/constant';
import {
  cleanUpWhatsappNumber,
  convertUnixToSingaporeTime,
  extractUserIdFromUrl,
} from '@utils/utils';
import { Table } from 'antd';
import { useDispatch } from 'react-redux';
import { showAlert } from '@store/alertSlice';
import {
  useCreateProactiveScamEnablersV2Mutation,
  useGetProactiveScamEnablersExistV2Mutation,
} from '@api/scamEnablerSlice';
import moment from 'moment';

const validColumns = [
  {
    title: 'No.',
    key: 'index',
    render: (text: string, record: any, index: number) => index + 1,
    width: '7%',
    textWrap: 'word-break',
  },
  {
    title: 'Profile Name',
    dataIndex: 'profilename',
    key: 'profilename',
    width: '7%',
  },
  {
    title: 'URL',
    dataIndex: 'url',
    key: 'url',
    width: '6%',
  },
  {
    title: 'User Id',
    dataIndex: 'scamEnablerUserId',
    key: 'scamEnablerUserId',
    width: '7%',
  },
  {
    title: 'Profile Type',
    dataIndex: 'profileType',
    key: 'profileType',
    width: '7%',
  },
  {
    title: 'No of Ads',
    dataIndex: 'numberOfAds',
    key: 'numberOfAds',
    width: '6%',
  },
  {
    title: 'Scam Type',
    dataIndex: 'scamType',
    key: 'scamType',
    width: '6%',
  },
  {
    title: 'Submission Date',
    dataIndex: 'submissionDate',
    key: 'submissionDate',
    width: '11%',
    render: (text: string, record: any, index: number) =>
      convertUnixToSingaporeTime(record.submittedAt),
  },
  // {
  //   title: 'Submission Time',
  //   dataIndex: 'submissionTime',
  //   key: 'submissionTime',
  //   width: '10%',
  // },
  {
    title: 'Reference Number',
    dataIndex: 'referenceNo',
    key: 'referenceNo',
    width: '7%',
  },
  {
    title: 'Remarks',
    dataIndex: 'remarks',
    key: 'remarks',
    width: '7%',
  },
  {
    title: 'Facebook Page Created Date',
    dataIndex: 'facebookCreatedAt',
    key: 'facebookCreatedAt',
    width: '10%',
  },
  {
    title: "Scammer's Contact Number",
    dataIndex: 'scammerContactNumber',
    key: 'scammerContactNumber',
    width: '7%',
  },
];

const invalidColumns = [
  {
    title: 'No.',
    key: 'index',
    render: (text: string, record: any, index: number) => index + 1,
    width: '7%',
  },
  {
    title: 'Profile Name',
    dataIndex: 'profilename',
    key: 'profilename',
    width: '7%',
  },
  {
    title: 'URL',
    dataIndex: 'url',
    key: 'url',
    width: '7%',
  },
  {
    title: 'User Id',
    dataIndex: 'scamEnablerUserId',
    key: 'scamEnablerUserId',
    width: '7%',
  },
  {
    title: 'Profile Type',
    dataIndex: 'profileType',
    key: 'profileType',
    width: '7%',
  },
  {
    title: 'No of Ads',
    dataIndex: 'numberOfAds',
    key: 'numberOfAds',
    width: '7%',
  },
  {
    title: 'Scam Type',
    dataIndex: 'scamType',
    key: 'scamType',
    width: '7%',
  },
  {
    title: 'Submission Date',
    dataIndex: 'submittedAt',
    key: 'submittedAt',
    width: '7%',
    render: (text: string, record: any, index: number) =>
      convertUnixToSingaporeTime(record.submittedAt),
  },
  // {
  //   title: 'Submission Time',
  //   dataIndex: 'submissionTime',
  //   key: 'submissionTime',
  //   width: '7%',
  // },
  {
    title: 'Reference Number',
    dataIndex: 'referenceNo',
    key: 'referenceNo',
    width: '7%',
  },
  {
    title: 'Remarks',
    dataIndex: 'remarks',
    key: 'remarks',
    width: '7%',
  },
  {
    title: 'Facebook Page Created Date',
    dataIndex: 'facebookCreatedAt',
    key: 'facebookCreatedAt',
    width: '7%',
  },
  {
    title: "Scammer's Contact Number",
    dataIndex: 'scammerContactNumber',
    key: 'scammerContactNumber',
    width: '7%',
  },
  {
    title: 'Reason',
    dataIndex: 'errorMessage',
    key: 'errorMessage',
    width: '7%',
    render: (text: string, record: any, index: number) => (
      <p className="text-red whitespace-pre-line">{text}</p>
    ),
  },
];
const CreateProactiveMonikerExcelModal = ({
  id,
  isOpen,
  onClose,
  callback,
}: {
  id: string;
  isOpen: boolean;
  onClose: () => void;
  callback?: () => void;
}) => {
  const formMethods = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [createProactiveScamEnabler] = useCreateProactiveScamEnablersV2Mutation();

  const [validCsvData, setValidCsvData] = useState<any[]>([]); // Specify the type of csvData as an array of objects
  const [invalidCsvData, setInvalidCsvData] = useState<any[]>([]); // Specify the type of csvData as an array of objects
  const [tabValue, setTabValue] = useState('valid');
  const dispatch = useDispatch();
  const [checkUserId] = useGetProactiveScamEnablersExistV2Mutation();
  const [isFileVerifying, setIsFileVerifying] = useState(false);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTabValue(newValue);
  };

  const validatePlatform = (element: any) => {
    let allowedPlatform = platform;
    if (
      element?.platform &&
      allowedPlatform
        .map((platform) => platform.toLowerCase())
        .includes(element.platform.toLowerCase())
    ) {
      return allowedPlatform.find(
        (platform) => platform.toLowerCase() === element.platform.toLowerCase(),
      );
    }
    return null;
  };

  const validateScamType = (element: any) => {
    let allowedScamType = scamType;
    if (!element.scamType) return '';
    else if (
      element.scamType &&
      allowedScamType
        .map((scamType) => scamType.toLowerCase())
        .includes(element.scamType.toLowerCase())
    ) {
      return allowedScamType.find(
        (scamType) => scamType.toLowerCase() === element.scamType.toLowerCase(),
      );
    }
    return false;
  };

  const validateAndFormatFields = (element: any) => {
    const platform = validatePlatform(element);
    const scamType = validateScamType(element);

    if (platform === 'Facebook' && element.url) {
      element.scamEnablerUserId = extractUserIdFromUrl(element.url, 'Facebook').userId;
    }
    const scamEnablerUserId =
      platform === 'Whatsapp'
        ? cleanUpWhatsappNumber(element.scamEnablerUserId)
        : element.scamEnablerUserId;
    return { platform, scamEnablerUserId, scamType };
  };

  const setJSONData = async (jsonData: any[]) => {
    setIsFileVerifying(true);
    let tempValidData: any = [];
    let validData: any = [];
    let invalidData: any = [];
    jsonData.forEach((element) => {
      element.platform = 'Facebook';
      const { platform, scamEnablerUserId, scamType } = validateAndFormatFields(element);
      if (element.numberOfAds) {
        element.numberOfAds = parseInt(element.numberOfAds);
      }
      let errorMessage = '';
      if (!element.scamEnablerUserId) {
        errorMessage += 'User ID is required\n\n';
      }
      if (!platform) {
        errorMessage += 'Invalid platform\n\n';
      }
      if (platform === 'Whatsapp' && !scamEnablerUserId) {
        errorMessage += 'Invalid Whatsapp number\n\n';
      }
      if (scamType === false) {
        errorMessage += 'Invalid scam type\n\n';
      }
      if (
        (!element.submissionDate && element.submissionTime) ||
        (element.submissionDate && !element.submissionTime)
      ) {
        errorMessage += 'Submission Date and Time are required\n\n';
      }
      if (element.submissionDate && element.submissionTime) {
        // const dateTimeString = `${element.submissionDate} ${formattedTime}`;
        // console.log('dataTimeString', dateTimeString);
        element.submittedAt = parseDateString(element.submissionDate, element.submissionTime);
        delete element.submissionTime;
        delete element.submissionDate;
        if (!element.submittedAt) {
          errorMessage +=
            'Invalid Submission DateTime Format, submission date to be in the format DD MMM YYYY or DD/MM/YYYY. Submission time to be in the format HHmm\n\n';
        }
      }

      if (element.profileType && (element.profileType !== 'User' && element.profileType !== 'Page')) {
        errorMessage += 'Invalid Profile Type\n\n';
      }
      if (errorMessage) {
        invalidData.push({ ...element, platform, scamType, scamEnablerUserId, errorMessage });
      } else {
        tempValidData.push({
          scamEnablerUserId,
          platform: platform,
        });
      }
    });
    let response = await checkUserId({
      proactiveScamEnablers: tempValidData,
    }).unwrap();
    response.result.forEach((item: any) => {
      let element = jsonData.find(
        (element) => element.scamEnablerUserId === item.scamEnablerUserId,
      );

      if (item.exists === false) {
        validData.push({
          ...element,
        });
      } else {
        invalidData.push({
          ...element,
          errorMessage: 'Unable to create, moniker Id already exists',
        });
      }
    });

    setValidCsvData(validData);
    setInvalidCsvData(invalidData);
    setIsFileVerifying(false);
  };

  const parseDateString = (dateString: string, timeString: string) => {
    const formattedTime =
      timeString.length === 3
        ? timeString.slice(0, 1) + ':' + timeString.slice(2)
        : timeString.slice(0, 2) + ':' + timeString.slice(2);
    const dateTimeString = `${dateString} ${formattedTime}`;

    // Define the supported formats
    const formats = [
      'D MMM YYYY HH:mm',
      'DD MMM YYYY HH:mm',
      'DD/MM/YYYY HH:mm',
      'D/MM/YYYY HH:mm',
    ];

    // Parse the date string using moment with the supported formats
    const parsedDate = moment(dateTimeString, formats, true);
    // Check if the date is valid
    if (!parsedDate.isValid()) {
      return '';
    }

    return parsedDate.valueOf();
  };

  const showCsvContent = async () => {
    let uploadedFile = cloneDeep(formMethods.getValues(id));
    if (!uploadedFile[0]?.fileContent) {
      setValidCsvData([]);
      setInvalidCsvData([]);
      return;
    }
    uploadedFile[0].fileContent = uploadedFile[0].fileContent.split(',')[1];
    let csvData = Buffer.from(uploadedFile[0].fileContent, 'base64').toString('utf8');
    let jsonData = await csv().fromString(csvData);
    setJSONData(jsonData);
  };

  const onUpload = async () => {
    if (validCsvData.length <= 0) return;
    let createRequest: any = [];
    validCsvData.forEach((element) => {
      let tempElement = cloneDeep(element);
      // if (element.submissionDate && element.submissionTime) {
      //   const formattedTime =
      //     element.submissionTime.length === 3
      //       ? element.submissionTime.slice(0, 1) + ':' + element.submissionTime.slice(2)
      //       : element.submissionTime.slice(0, 2) + ':' + element.submissionTime.slice(2);
      //   // const dateTimeString = `${element.submissionDate} ${formattedTime}`;
      //   // console.log('dataTimeString', dateTimeString);
      //   tempElement.submittedAt = parseDateString(element.submissionDate, formattedTime);
      //   delete tempElement.submissionTime;
      //   delete tempElement.submissionDate;
      // }
      if (element.scammerContactNumber) {
        tempElement.scammerContactNumber = tempElement.scammerContactNumber.split(',');
      }
      if (element.attachmentFileNames) {
        tempElement.attachmentFileNames = tempElement.attachmentFileNames.split(',');
      }
      if (element.facebookCreatedAt) {
        tempElement.facebookCreatedAt = new Date(element.facebookCreatedAt).getTime();
      }
      if (!element.numberOfAds) {
        delete tempElement.numberOfAds;
      }
      createRequest.push(tempElement);
    });

    // console.log('createRequest', createRequest);
    setIsLoading(true);
    let response: any = await createProactiveScamEnabler(createRequest);

    if (!response.error) {
      dispatch(
        showAlert({
          type: 'success',
          message: `Update Successful`,
        }),
      );
      if (callback) callback();
      onClose();
    }
    setIsLoading(false);
  };

  const handleDownloadTemplate = () => {
    const link = document.createElement('a');
    link.href = '/assets/template/proactive_csv_upload_template.csv';
    link.download = 'SATIS+ Proactive Moniker CSV Upload Template.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalLayout
        title="Add Report - Upload List"
        handleCloseModal={onClose}
        alignment="center"
        size="large"
        overflowClass="overflow-hidden"
      >
        <div>
          <CommonButton
            className="pb-3"
            title="Download CSV Template"
            variant="text-blue"
            onClick={handleDownloadTemplate}
            type="button"
          />
        </div>
        <form>
          <FileUpload
            id={id}
            control={formMethods.control}
            setValue={formMethods.setValue}
            getValues={formMethods.getValues}
            onClose={onClose}
            fileType={['text/csv']}
            maxFiles={1}
            callback={showCsvContent}
            isLoading={isFileVerifying}
            allowPaste={false}
          />
        </form>
        {((validCsvData && validCsvData.length > 0) ||
          (invalidCsvData && invalidCsvData.length > 0)) && (
          <>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              TabIndicatorProps={{ style: { backgroundColor: '#255662' } }}
            >
              <Tab
                label={
                  <div className="flex items-center gap-4">
                    <p
                      className={`font-bold ${tabValue === 'valid' ? 'text-blue-500' : 'text-blue-400'}`}
                      style={{ textTransform: 'none' }}
                    >
                      Successful
                    </p>
                    <Badge color="success" badgeContent={validCsvData.length || 0} max={999} />
                  </div>
                }
                value="valid"
              />
              <Tab
                label={
                  <div className="flex items-center gap-4">
                    <p
                      className={`font-bold ${tabValue === 'invalid' ? 'text-blue-500' : 'text-blue-400'}`}
                      style={{ textTransform: 'none' }}
                    >
                      Error
                    </p>
                    <Badge color="error" badgeContent={invalidCsvData.length || 0} max={999} />
                  </div>
                }
                value="invalid"
              />
            </Tabs>
            {tabValue === 'valid' && (
              <div className="h-[40vh] overflow-y-auto">
                <Table
                  rowKey="scamEnablerUserId"
                  dataSource={cloneDeep(validCsvData)}
                  columns={validColumns}
                  pagination={false}
                  scroll={{ x: 'max-content' }} // Enable horizontal scrolling
                  sticky
                />
              </div>
            )}
            {tabValue === 'invalid' && (
              <div className="h-[40vh] overflow-y-auto">
                <Table
                  rowKey="scamEnablerUserId"
                  dataSource={cloneDeep(invalidCsvData)}
                  columns={invalidColumns}
                  pagination={false}
                  scroll={{ x: 'max-content' }} // Enable horizontal scrolling
                  sticky
                />
              </div>
            )}
          </>
        )}
        <div className="flex justify-end gap-4 pt-4">
          <CommonButton isDisabled={false} variant="text" title={`Cancel`} onClick={onClose} />
          <CommonButton
            isLoading={isLoading}
            variant="primary"
            title="Save"
            onClick={onUpload}
            isDisabled={validCsvData.length <= 0}
          />
        </div>
      </ModalLayout>
    </Modal>
  );
};

export default CreateProactiveMonikerExcelModal;
