import { useDispatch } from 'react-redux';
import { apiSlice } from './apiSlice';
import { showAlert } from '@store/alertSlice';
import { ReportDetailedInterface } from '@interface/ScamEnablerInterface';

const defaultReportStatus = 'pendingReview,underReview,inProgress,pendingStakeholders,caseClosed';
export const reportsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    formCreateReport: builder.mutation({
      query: (params) => {
        return {
          method: 'POST',
          body: params,
          url: `/v1/formreport`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    createReport: builder.mutation({
      query: (params) => {
        return {
          method: 'POST',
          body: params,
          url: `/v1/report`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    // assignUsers: builder.mutation({
    //   query: (params) => {
    //     return {
    //       method: 'PUT',
    //       body: params,
    //       url: `/v1/report/assignusers`,
    //     };
    //   },
    //   transformResponse: (response: any) => {
    //     return response;
    //   },
    // }),
    updateAssesmentFindings: builder.mutation({
      query: (params) => {
        return {
          method: 'POST',
          body: params,
          url: `/v1/report/assessmentfindings`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    // updateReportStatus: builder.mutation({
    //   query: (params) => {
    //     return {
    //       method: 'PUT',
    //       body: params,
    //       url: `/v1/report/status`,
    //     };
    //   },
    //   transformResponse: (response: any) => {
    //     return response;
    //   },
    // }),
    checkReportExist: builder.mutation({
      query: (params) => {
        return {
          method: 'POST',
          body: params,
          url: `/v1/report/checkexist`,
          responseHandler: (response) => response.blob(), // handle the response as a blob
        };
      },
      transformResponse: (response: any) => {
        // Assuming the response is a string of CSV data
        const blob = new Blob([response], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = 'report.csv'; // or any other filename you want
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        return response;
      },
    }),
    createReportCsvV2: builder.mutation({
      query: (params) => {
        return {
          method: 'POST',
          body: params,
          url: `/v2/reports/csv`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),

    getDiaryEntries: builder.query({
      query: (params) => {
        return {
          url: `v2/report/getDiaryEntries/${params.reportId}`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    createDiaryEntry: builder.mutation({
      query: (params) => {
        return {
          method: 'POST',
          body: params,
          url: `v2/report/newDiaryEntry`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),

    getReportDetailsByIdV2: builder.query({
      query: (params) => {
        return {
          url: `/v2/report/${params.id}`,
        };
      },
      transformResponse: (response: any) => {
        return response as ReportDetailedInterface;
      },
    }),

    getReportInfo: builder.query({
      query: (request) => {
        let params = {
          page: request.params.page,
          size: request.params.size,
          search: request.params.search,
          search_fields : request?.params?.search_fields || "",
          projection : request?.params?.projection || "",
          sort_by: request.params.sort_by,
          order_by: request.params.order_by,
          // report_status: request.params.filters?.report_status,
          report_status: request.params.filters?.report_status,
          scam_type: request.params.filters?.scam_type,
          classification: request.params.filters?.classification,
          created_date_from: request.params.filters?.created_date_from,
          created_date_to: request.params.filters?.created_date_to,
        };

        return {
          url: `v2/reports`,
          params,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),

    updateReportInfo: builder.mutation({
      query: (params) => {
        return {
          method: 'PUT',
          body: params,
          url: `/v2/reports`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
  }),
});

// export const useUpdateReportStatus = () => {
//   const [updateReportStatus] = useUpdateReportStatusMutation();
//   const dispatch = useDispatch();

//   const update = async (reportId: string, reportStatus: string) => {
//     const response: any = await updateReportStatus({
//       reportId,
//       reportStatus,
//     });

//     if (!response.error) {
//       dispatch(
//         showAlert({
//           type: 'success',
//           message: `Update Successful`,
//         }),
//       );
//     }
//     return response;
//   };

//   return update;
// };

// export interface assignUserParameters {
//   reportId: string;
//   userId: string;
//   role: string;
// }

// export const useReportsAssign = () => {
//   const [assignReport] = useAssignUsersMutation();
//   const dispatch = useDispatch();

//   const assign = async (assignUserParams: Array<assignUserParameters>) => {
//     let response: any = await assignReport(assignUserParams);
//     console.log('response', response.error);
//     if (!response.error) {
//       dispatch(
//         showAlert({
//           type: 'success',
//           message: `Report(s) assigned successfully to ${
//             assignUserParams[0].userId
//           } as the ${assignUserParams[0].role.toUpperCase()}`,
//         }),
//       );
//     }
//     return response;
//   };

//   return assign;
// };

export const {
  useFormCreateReportMutation,
  useCreateReportMutation,
  useGetReportDetailsByIdV2Query,
  // useAssignUsersMutation,
  useUpdateAssesmentFindingsMutation,
  // useUpdateReportStatusMutation,
  useCheckReportExistMutation,
  useCreateReportCsvV2Mutation,
  useGetDiaryEntriesQuery,
  useCreateDiaryEntryMutation,
  useGetReportInfoQuery,
  useLazyGetReportInfoQuery,
  useUpdateReportInfoMutation,
  useLazyGetReportDetailsByIdV2Query,
} = reportsApiSlice;
