import {
  financialExchangePlatform,
  paynowOutcomesUpdatable,
  accountTypes,
  transactionTypes,
} from '@interface/constant';
import { FormItemInterface } from '@interface/dynamicFormInterface';
import { paynowOutcomes } from '@interface/constant';

export const paynowFilterGeneralItems: Array<FormItemInterface> = [
  {
    label: 'Account Status',
    type: 'dropdownMulti',
    id: 'outcome',
    placeholder: 'Please Select',
    extras: { dropdownData: paynowOutcomes },
    alignment: 'row',
  },
  {
    label: 'Name of Bank',
    type: 'dropdownMulti',
    id: 'platform',
    placeholder: 'Please Select',
    extras: { dropdownData: financialExchangePlatform },
    alignment: 'row',
  },
  {
    label: 'DO Assigned',
    type: 'dropdownUser',
    id: 'officer_assigned',
    // TODO: replace usergroup
    extras: { userGroup: 'scamEnablers' },
    alignment: 'row',
  },
  {
    label: 'Date of Latest Transfer',
    type: 'calendarRange',
    id: 'created_date_from,created_date_to',
    placeholder: 'dd/mm/yyyy',
    alignment: 'row',
  },
];

export const overviewInformationItemsGeneral: Array<FormItemInterface> = [
  {
    label: 'HP/NRIC/FIN/UEN',
    id: 'paynowNumber',
    type: 'label',
    alignment: 'half-row',
  },
  {
    label: 'PayNow Account Status',
    id: 'outcome',
    type: 'labelEditDropdown',
    alignment: 'half-row',
    extras: {
      dropdownData: paynowOutcomes,
      type: 'paynow',
    },
  },
  {
    label: 'Date First Created',
    id: 'createdAt',
    type: 'labelEditDateTime',
    alignment: 'half-row',
  },
  {
    label: 'Bank Display Name (Submitted)',
    id: 'bankDisplayName',
    type: 'label',
    alignment: 'half-row',
  },
];

export const overviewFormItemsGeneral: Array<FormItemInterface> = [
  {
    label: 'HP/NRIC/FIN/UEN',
    id: 'paynowNumber',
    type: 'label',
    alignment: 'half-row',
  },
  {
    label: 'PayNow Account Status',
    id: 'outcome',
    type: 'labelEditDropdown',
    alignment: 'half-row',
    extras: {
      dropdownData: paynowOutcomes,
      type: 'paynow',
    },
  },
  {
    label: 'Date First Created',
    id: 'createdAt',
    type: 'label',
    alignment: 'half-row',
  },
  {
    label: 'Bank Display Name',
    id: 'displayName',
    type: 'labelEditInput',
    alignment: 'half-row',
  },
];

export const reportInformationForAccount: Array<FormItemInterface> = [
  {
    label: 'Report No.',
    id: 'transactionSubmissions',
    type: 'label',
  },
];

export const addToReportForm: Array<FormItemInterface> = [
  {
    label: 'Please select the report to add the account',
    id: 'reportNumber',
    type: 'dropdown',
    alignment: 'column',
    extras: {
      dropdownData: [],
    },
  },
];
