import { FormItemInterface } from '@interface/dynamicFormInterface';
import { designations } from '@interface/constant';

export const userFormGeneralItems: Array<FormItemInterface> = [
  {
    label: 'Email',
    type: 'textInput',
    id: 'email',
    placeholder: 'user@spf.gov.sg',
    alignment: 'column',
    disabled: true,
  },
  {
    label: 'First Name',
    type: 'textInput',
    id: 'firstName',
    placeholder: 'Daniel',
    alignment: 'column',
    rules: {
      required: 'Please enter a valid First Name',
    },
  },
  {
    label: 'Last Name',
    type: 'textInput',
    id: 'lastName',
    placeholder: 'Tan',
    alignment: 'column',
    rules: {
      required: 'Please enter a valid First Name',
    },
  },
  {
    label: 'Phone No.',
    id: 'contactNumber',
    type: 'telephoneInput',
    alignment: 'column',
  },
  {
    label: 'Designation',
    id: 'designation',
    type: 'dropdown',
    placeholder: 'Please select designation',
    extras: { dropdownData: designations },
    alignment: 'column',
    rules: {
      required: 'Please select a designation',
    },
  },
  {
    label: 'Role',
    type: 'dropdownMulti',
    id: 'role',
    placeholder: 'Please select role',
    extras: { dropdownData: [] },
    alignment: 'column',
  },
  {
    label: 'Teams',
    type: 'dropdownMulti',
    id: 'teams',
    placeholder: 'Please select teams',
    extras: { dropdownData: [] },
    alignment: 'column',
  },
  {
    label: 'Active',
    type: 'toggle',
    id: 'active',
    value: true,
    alignment: 'column',
  },
];
