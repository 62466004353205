// src/Timeline.tsx

import { Timeline } from 'antd';
import ModalLayout from '@components/layouts/ModalLayout';
import { Modal } from '@mui/material';
import styled from 'styled-components';
import { ReactComponent as ActivityLogEdit } from '@assets/icons/activity_log_edit.svg';
import { useGetActivtiyLogQuery } from '@api/activityLogSlice';
import { convertUnixToSingaporeTime } from '@utils/utils';
import { useEffect, useState } from 'react';
import { overviewItems, monikerItems } from '../ProactiveMonikersFormConfig';
import { CommonButton } from '@components/common/CommonButton';
// import AssessmentFindingsModal from './AssessmentFindingsModal';
const { Item } = Timeline;

const CustomTimeline = styled(Timeline)`
  .ant-timeline-item-head {
  }

  .ant-timeline-item-content {
    position: relative;
    left: 10px;
    padding: 4px;
  }

  .timeline-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .timeline-text {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
  }

  .timeline-datetime {
    font-size: 14px;
    color: #999;
  }
`;

const ViewProactiveActivityLogsModal = ({
  proactiveScamEnablerId,
  activityLogsModalOpen,
  onActivityLogsModalClose,
}: {
  proactiveScamEnablerId: any;
  activityLogsModalOpen: boolean;
  onActivityLogsModalClose: any;
}) => {
  const {
    data: activityLogs,
    isLoading,
    refetch: refetchActivityLogs,
  } = useGetActivtiyLogQuery(
    {
      id: proactiveScamEnablerId,
      by: 'proactiveScamEnablerId',
    },
    { refetchOnMountOrArgChange: true },
  );

  const [activityLogItems, setActivityLogItems] = useState<any[]>([]);

  useEffect(() => {
    if (!activityLogsModalOpen) {
      setActivityLogItems([]);
    }
  }, [activityLogsModalOpen, setActivityLogItems]);

  const getUpdateDetails = (details: any) => {
    // Create an array of components
    const components = Object.entries(details).map(([key, value]) => getMonikerItem(key, value));

    // Render the array of components
    return <>{components}</>;
  };

  const getMonikerItem = (key: string, value: any) => {
    let config = monikerItems.concat(overviewItems);
    let item = config.find((item) => {
      return item.id === key;
    });
    if (item) {
      if (item.id === 'attachmentFileNames') {
        value = value.join(', ');
      }
      return (
        <p key={key}>
          <b>{item.label}</b> to{' '}
          <b>
            {item.type === 'labelEditDateTime' || item.type === 'date' || item.type === 'dateTime'
              ? convertUnixToSingaporeTime(value)
              : value}
          </b>
        </p>
      );
    }
  };

  useEffect(() => {
    let tempItems: any = [];
    if (activityLogs && activityLogs.activityLogs) {
      const sortedLogs = [...activityLogs.activityLogs].sort((a, b) => b.createdAt - a.createdAt);

      sortedLogs.forEach((log: any) => {
        let tempItem: any = {};
        switch (log.action) {
          case 'updateProactiveScamEnabler':
            tempItem.dot = (
              <div className="w-8 h-8 rounded-full bg-blue-400 text-white flex justify-center items-center font-bold">
                <ActivityLogEdit />
              </div>
            );
            tempItem.children = (
              <div className="timeline-content">
                <div className="timeline-text">
                  <b>{log.userName}</b> edited the following details:{' '}
                  {getUpdateDetails(log.details)}
                </div>
                <div className="timeline-datetime">{convertUnixToSingaporeTime(log.createdAt)}</div>
              </div>
            );
            break;

          case 'updateProactiveScamEnablerAttachment':
            tempItem.dot = (
              <div className="w-8 h-8 rounded-full bg-blue-400 text-white flex justify-center items-center font-bold">
                <ActivityLogEdit />
              </div>
            );
            tempItem.children = (
              <div className="timeline-content">
                <div className="timeline-text">
                  <b>{log.userName}</b> added screenshots{' '}
                  <b>
                    {log.details.join('\n')}
                    {/* {log.details.attachments.map((screenshot: string) => {
                      return screenshot;
                    })} */}
                  </b>
                </div>
                <div className="timeline-datetime">{convertUnixToSingaporeTime(log.createdAt)}</div>
              </div>
            );
            break;

          case 'deleteProactiveScamEnablerAttachment':
            tempItem.dot = (
              <div className="w-8 h-8 rounded-full bg-blue-400 text-white flex justify-center items-center font-bold">
                <ActivityLogEdit />
              </div>
            );
            tempItem.children = (
              <div className="timeline-content">
                <div className="timeline-text">
                  <b>{log.userName}</b> deleted screenshots{' '}
                  <b>
                    {log.details.join('\n')}
                    {/* {log.details.attachments.map((screenshot: string) => {
                      return screenshot;
                    })} */}
                  </b>
                </div>
                <div className="timeline-datetime">{convertUnixToSingaporeTime(log.createdAt)}</div>
              </div>
            );
            break;
          case 'createProactiveScamEnabler':
            tempItem.dot = (
              <div className="w-8 h-8 rounded-full bg-blue-400 text-white flex justify-center items-center font-bold">
                <ActivityLogEdit />
              </div>
            );
            tempItem.children = (
              <div className="timeline-content">
                <div className="timeline-text">
                  <b>{log.userName}</b> created ProactiveScamEnabler
                </div>
                <div className="timeline-datetime">{convertUnixToSingaporeTime(log.createdAt)}</div>
              </div>
            );
            break;
          default:
            break;
        }
        tempItems.push(tempItem);
      });
    }
    setActivityLogItems(tempItems);
  }, [activityLogs, activityLogsModalOpen]);

  return (
    <>
      <Modal open={activityLogsModalOpen} onClose={onActivityLogsModalClose}>
        <ModalLayout
          title={'Activity Log'}
          handleCloseModal={() => {
            onActivityLogsModalClose();
          }}
          alignment="right"
          size="full"
        >
          {isLoading && <div className="animate-pulse bg-grey-300 h-[80vh] w-full"></div>}
          {!isLoading && activityLogItems && activityLogItems.length > 0 && (
            <CustomTimeline items={activityLogItems} />
          )}
        </ModalLayout>
      </Modal>
    </>
  );
};

export default ViewProactiveActivityLogsModal;
