import {
  classifications,
  financialExchangePlatform,
  platform,
  policeStations,
  purposeOfSubmission,
  scamType,
  useOfScamEnabler,
} from '@interface/constant';
import { FormItemInterface } from '@interface/dynamicFormInterface';
import { getFileMimeTypes } from '@utils/utils';
import validator from 'validator';

export const faceBookItems: Array<FormItemInterface> = [
  {
    label: 'URL',
    type: 'textInput',
    id: 'url',
    alignment: 'column',
    extras: {
      keyup: (value: string) => {
        if (value.toLowerCase().startsWith('facebook.com/')) {
          return `https://www.${value}`;
        } else if (value.toLowerCase().startsWith('www.facebook.com/')) {
          return `https://${value}`;
        } else {
          return value;
        }
      },
    },
    rules: {
      required: 'Facebook URL is required',
      validate: {
        startsWithFacebook: (value: string) => {
          if (!value.toLowerCase().startsWith('https://www.facebook.com/'))
            return 'URL must start with https://www.facebook.com/';
        },
      },
    },
  },
  {
    label: 'Facebook User ID',
    type: 'textInput',
    id: 'userId',
    alignment: 'column',
    // extras: { prefix: "FBID#" },
    rules: {
      required: 'Facebook User Id is required',
      pattern: {},
      validate: {
        isURL: (value: string) => {
          if (validator.isURL(value)) {
            return 'Please do not include "www.facebook.com" in the Facebook handle';
          }
        },
      },
    },
  },
  // {
  //   label: "Victim Report",
  //   type: "boolean",
  //   id: "victimReported",
  //   alignment: "column",
  //   rules: {
  //     required: "Please select an option"
  //   }
  // },
  // {
  //   label: "Interaction to any post/ads",
  //   type: "boolean",
  //   id: "interactionPostAds",
  //   alignment: "column",
  //   rules: {
  //     required: "Please select an option"
  //   }
  // },
  // {
  //   label: "Number of Victims",
  //   type: "textInput",
  //   id: "numberOfVictims",
  //   alignment: "column",
  //   optional: true,
  // },
  // {
  //   label: "Scammer's Contact Number",
  //   type: 'telephoneInput',
  //   id: 'scammerContactNumber',
  //   alignment: 'column',
  //   optional: true,
  //   placeholder: 'e.g. +6591234567',
  //   rules: {
  //     pattern: {
  //       value: /^\+[0-9]+$/,
  //       message: 'Please enter a valid phone number. e.g. For Spain, +3512345678',
  //     },
  //   },
  // },
  {
    label: 'Screenshot(s)',
    type: 'fileUpload',
    id: 'attachments',
    extras: {
      fileType: getFileMimeTypes(['image', 'pdf', 'word']),
      maxFiles: 3,
    },
    alignment: 'column',
    optional: true,
  },
];

export const instagramItems: Array<FormItemInterface> = [
  {
    label: 'URL',
    type: 'textInput',
    id: 'url',
    alignment: 'column',
    extras: {
      keyup: (value: string) => {
        if (value.toLowerCase().startsWith('instagram.com/')) {
          return `https://www.${value}`;
        } else if (value.toLowerCase().startsWith('www.instagram.com/')) {
          return `https://${value}`;
        } else {
          return value;
        }
      },
    },
    rules: {
      required: 'Instagram URL is required',
      validate: {
        startsWithInstagram: (value: string) => {
          if (!value.toLowerCase().startsWith('https://www.instagram.com/'))
            return 'URL must start with https://www.instagram.com/';
        },
      },
    },
  },
  {
    label: 'Instagram User ID',
    type: 'textInput',
    id: 'userId',
    alignment: 'column',
    // extras: { prefix: "IGID#" },
    rules: {
      required: 'Instagram User Id is required',
      pattern: {},
      validate: {
        isURL: (value: string) => {
          if (validator.isURL(value)) {
            return 'Please do not include "www.instagram.com" in the Instagram handle';
          }
        },
      },
    },
  },
  {
    label: 'Screenshot(s)',
    type: 'fileUpload',
    id: 'attachments',
    extras: {
      fileType: getFileMimeTypes(['image', 'pdf', 'word']),
      maxFiles: 3,
    },
    alignment: 'column',
    optional: true,
  },
];

export const googlePlaystoreApplicationItems: Array<FormItemInterface> = [
  {
    label: 'URL',
    type: 'textInput',
    id: 'url',
    alignment: 'column',
    extras: {},
    rules: {
      required: 'Google Playstore application URL is required',
      validate: {
        isURL: (value: string) => {
          if (!validator.isURL(value)) {
            return 'Please enter valid url';
          }
        },
      },
    },
  },
  {
    label: 'Application Name',
    type: 'textInput',
    id: 'userId',
    alignment: 'column',
    rules: {
      required: 'Application Name is required',
      pattern: {},
    },
  },
  {
    label: 'Screenshot(s)',
    type: 'fileUpload',
    id: 'attachments',
    extras: {
      fileType: getFileMimeTypes(['image', 'pdf', 'word']),
      maxFiles: 3,
    },
    alignment: 'column',
    optional: true,
  },
];

export const carousellItems: Array<FormItemInterface> = [
  {
    label: 'Carousell User ID/Seller listing',
    type: 'textInput',
    id: 'userId',
    alignment: 'column',
    rules: {
      required: 'Carousell User Id is required',
      pattern: {},
      validate: {
        isURL: (value: string) => {
          if (validator.isURL(value)) {
            return 'Please do not include "https://www.carousell.sg" in the Carousell handle';
          }
        },
      },
    },
  },
  {
    label: 'Screenshot(s)',
    type: 'fileUpload',
    id: 'attachments',
    extras: {
      fileType: getFileMimeTypes(['image', 'pdf', 'word']),
      maxFiles: 5,
    },
    alignment: 'column',
    optional: true,
  },
];

export const LazadaItems: Array<FormItemInterface> = [
  {
    label: 'Lazada User ID/Seller listing',
    type: 'textInput',
    id: 'userId',
    alignment: 'column',
    rules: {
      required: 'Lazada User Id is required',
      pattern: {},
      validate: {
        isURL: (value: string) => {
          if (validator.isURL(value)) {
            return 'Please do not include "www.lazada.com" in the Lazada handle';
          }
        },
      },
    },
  },
  {
    label: 'Screenshot(s)',
    type: 'fileUpload',
    id: 'attachments',
    extras: {
      fileType: getFileMimeTypes(['image', 'pdf', 'word']),
      maxFiles: 3,
    },
    alignment: 'column',
    optional: true,
  },
];

export const ShopeeItems: Array<FormItemInterface> = [
  {
    label: 'Shopee Link',
    type: 'textInput',
    id: 'userId',
    alignment: 'column',
    extras: {
      keyup: (value: string) => {
        if (value.toLowerCase().startsWith('shopee.sg')) {
          return `https://${value}`;
        } else {
          return value;
        }
      },
    },
    rules: {
      required: 'Shopee URL is required',
      pattern: {},
      validate: {
        startsWithShopee: (value: string) => {
          if (!value.toLowerCase().startsWith('https://shopee.sg/'))
            return 'URL must start with https://shopee.sg/';
        },
      },
    },
  },
  {
    label: 'Screenshot(s)',
    type: 'fileUpload',
    id: 'attachments',
    extras: {
      fileType: getFileMimeTypes(['image', 'pdf', 'word']),
      maxFiles: 3,
    },
    alignment: 'column',
    optional: true,
  },
];

export const telegramItems: Array<FormItemInterface> = [
  {
    label: 'Telegram handle',
    type: 'textInput',
    id: 'userId',
    alignment: 'column',
    rules: {
      required: 'Telegram handle is required',
      pattern: {},
      validate: {
        isURL: (value: string) => {
          if (validator.isURL(value)) {
            return 'Please do not include "https://t.me/" in the Telegram handle';
          }
        },
      },
    },
  },
  {
    label: 'Screenshot(s)',
    type: 'fileUpload',
    id: 'attachments',
    extras: {
      fileType: getFileMimeTypes(['image', 'pdf', 'word']),
      maxFiles: 3,
    },
    alignment: 'column',
    optional: true,
  },
];

export const twitterItems: Array<FormItemInterface> = [
  {
    label: 'X Username/Link',
    type: 'textInput',
    id: 'userId',
    alignment: 'column',
    rules: {
      required: 'X Username/Link is required',
      pattern: {},
      validate: {
        isURL: (value: string) => {
          if (validator.isURL(value)) {
            return 'Please do not include "www.x.com" in the X handle';
          }
        },
      },
    },
  },
  {
    label: 'Screenshot(s)',
    type: 'fileUpload',
    id: 'attachments',
    extras: {
      fileType: getFileMimeTypes(['image', 'pdf', 'word']),
      maxFiles: 3,
    },
    alignment: 'column',
    optional: true,
  },
];

// export const urlItems: Array<FormItemInterface> = [
//   {
//     label: 'URL',
//     type: 'textInput',
//     id: 'userId',
//     alignment: 'column',
//     placeholder: 'e.g. https://website.com',
//     rules: {
//       required: 'Website URL is required',
//       validate: (value: string) => {
//         if (!validator.isURL(value)) {
//           return 'Invalid URL';
//         }
//       },
//     },
//   },
//   {
//     label: 'Screenshot(s)',
//     type: 'fileUpload',
//     id: 'attachments',
//     alignment: 'column',
//     optional: true,
//   },
// ];

export const urlItems: Array<FormItemInterface> = [
  {
    label: 'Website URL',
    type: 'tableInput',
    id: 'url',
    alignment: 'column',
    placeholder: 'Please enter',
    rules: {
      required: 'Website URL is required',
      validate: {
        isURL: (value: string) => {
          if (!validator.isURL(value)) {
            return 'Invalid website URL. Please provide URL in the correct format e.g. https://website.com or http://website.com';
          }
        },
        isNotMoniker: (value: string) => {
          if (
            value.toLowerCase().includes('www.facebook.com') ||
            value.toLowerCase().includes('www.instagram.com') ||
            value.toLowerCase().includes('t.me/') ||
            value.toLowerCase().includes('www.carousell.') ||
            value.toLowerCase().includes('www.lazada.') ||
            value.toLowerCase().includes('www.shopee.sg') ||
            value.toLowerCase().includes('www.line.me') ||
            value.toLowerCase().includes('www.wechat.com') ||
            value.toLowerCase().includes('https://wa.me/') ||
            value.toLowerCase().includes('www.tiktok.com')
          ) {
            return 'Moniker URL is not permitted. Please enter under the appropriate scam enablers.';
          }
        },
      },
    },
  },
  {
    label: 'Screenshot(s)',
    type: 'fileUpload',
    id: 'attachments',
    extras: {
      fileType: getFileMimeTypes(['image', 'pdf', 'word']),
      maxFiles: 3,
    },
    alignment: 'column',
    optional: true,
  },
];

export const urlItemsMainTable: Array<FormItemInterface> = [
  {
    label: 'Website URL',
    type: 'tableInput',
    id: 'userId',
    alignment: 'column',
    extras: {
      tableData: urlItems,
    },
  },
];

export const whatsappItems: Array<FormItemInterface> = [
  {
    label: "Scammer's Contact Number",
    type: 'telephoneInput',
    id: 'userId',
    alignment: 'column',
    placeholder: 'e.g. +6591234567',
    rules: {
      required: "Scammer's Contact Number is required",
      pattern: {
        value: /^\+[0-9]+$/,
        message: 'Please enter a valid phone number. e.g. For Spain, +3512345678',
      },
    },
  },
  {
    label: 'Screenshot(s)',
    type: 'fileUpload',
    id: 'attachments',
    extras: {
      fileType: getFileMimeTypes(['image', 'pdf', 'word']),
      maxFiles: 3,
    },
    alignment: 'column',
    optional: true,
  },
];

export const rcsItems: Array<FormItemInterface> = [
  {
    label: "Scammer's Contact Number",
    type: 'telephoneInput',
    id: 'userId',
    alignment: 'column',
    placeholder: 'e.g. +6591234567',
    rules: {
      required: "Scammer's Contact Number is required",
      pattern: {
        value: /^\+[0-9]+$/,
        message: 'Please enter a valid phone number. e.g. For Spain, +3512345678',
      },
    },
  },
  {
    label: 'Screenshot(s)',
    type: 'fileUpload',
    id: 'attachments',
    extras: {
      fileType: getFileMimeTypes(['image', 'pdf', 'word']),
      maxFiles: 3,
    },
    alignment: 'column',
    rules: {
      required: true,
    },
  },
];

export const iMessageItems: Array<FormItemInterface> = [
  {
    label: 'Contact Method',
    type: 'radioToggle',
    id: 'userIdType',
    alignment: 'column',
    extras: {
      options: [
        { label: 'Phone Number', value: 'true', type: 'telephoneInput' },
        { label: 'Email', value: 'false', type: 'textInput' },
      ],
      confirmationLabel: 'userId',
      nestedFieldTypes: [
        {
          label: 'Phone No.',
          id: 'userId',
          type: 'telephoneInput',
          alignment: 'column',
          rules: {
            pattern: {
              value: /^\+[0-9]+$/,
              message: 'Please enter a valid phone number. e.g. For Spain, +3512345678',
            },
          },
        },
        {
          label: 'Email',
          id: 'userId',
          type: 'textInput',
          alignment: 'column',
          rules: {
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Please enter a valid email address',
            },
          },
        },
      ],
    },
  },
  {
    label: 'Screenshot(s)',
    type: 'fileUpload',
    id: 'attachments',
    extras: {
      fileType: getFileMimeTypes(['image', 'pdf', 'word']),
      maxFiles: 3,
    },
    alignment: 'column',
    rules: {
      required: true,
    },
  },
];

export const defaultItems: Array<FormItemInterface> = [
  {
    label: 'UserId',
    type: 'textInput',
    id: 'userId',
    alignment: 'column',
    rules: {
      pattern: {},
      required: 'Moniker User Id is required',
    },
  },
  {
    label: 'Screenshot(s)',
    type: 'fileUpload',
    id: 'attachments',
    extras: {
      fileType: getFileMimeTypes(['image', 'pdf', 'word']),
      maxFiles: 3,
    },
    alignment: 'column',
    optional: true,
  },
];

export const emailItems: Array<FormItemInterface> = [
  {
    label: 'Email Address',
    type: 'textInput',
    id: 'userId',
    alignment: 'column',
    rules: {
      required: 'Email address is required',
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'Please enter a valid email address',
      },
    },
  },
  {
    label: 'Screenshot(s)',
    type: 'fileUpload',
    id: 'attachments',
    extras: {
      fileType: getFileMimeTypes(['image', 'pdf', 'word']),
      maxFiles: 3,
    },
    alignment: 'column',
    optional: true,
  },
];

export const phoneNumberItems: Array<FormItemInterface> = [
  {
    label: 'Phone Number',
    type: 'telephoneInput',
    id: 'userId',
    alignment: 'column',
    placeholder: 'e.g. +6591234567',
    rules: {
      required: 'Phone Number is required',
      pattern: {
        value: /^\+[0-9]+$/,
        message: 'Please enter a valid phone number. e.g. For Spain, +3512345678',
      },
    },
  },
  {
    label: 'Screenshot(s)',
    type: 'fileUpload',
    id: 'attachments',
    extras: {
      fileType: getFileMimeTypes(['image', 'pdf', 'word']),
      maxFiles: 3,
    },
    alignment: 'column',
    rules: {
      required: true,
    },
  },
];

export const phoneNumberUserIdItems: Array<FormItemInterface> = [
  {
    label: "User ID/ Scammer's Contact Number",
    type: 'textInput',
    id: 'userId',
    alignment: 'column',
    rules: {
      required: "User ID/ Scammer's Contact Number is required",
    },
  },
  {
    label: 'Screenshot(s)',
    type: 'fileUpload',
    id: 'attachments',
    extras: {
      fileType: getFileMimeTypes(['image', 'pdf', 'word']),
      maxFiles: 3,
    },
    alignment: 'column',
    optional: true,
  },
];

export const createFormScamEnablersGeneralItems: Array<FormItemInterface> = [
  {
    label: 'Use of Scam Enabler',
    type: 'dropdown',
    id: 'useOfScamEnabler',
    alignment: 'column',
    extras: {
      dropdownData: useOfScamEnabler,
    },
  },
  {
    label: 'Platform',
    type: 'dropdown',
    id: 'platform',
    alignment: 'column',
    extras: {
      dropdownData: platform,
    },
  },
];

export const createFormGeneralItems: Array<FormItemInterface> = [
  {
    label: 'Police Station/Unit',
    type: 'dropdown',
    id: 'policeStation',
    placeholder: 'Please Select',
    extras: { dropdownData: policeStations },
    alignment: 'column',
    rules: {
      required: 'Please select an option',
    },
  },
  {
    label: 'SPF Email Address',
    type: 'textInput',
    id: 'spfEmail',
    placeholder: 'e.g user@spf.gov.sg',
    alignment: 'column',
    rules: {
      required: 'Please enter a valid SPF email address',
      pattern: {
        value: /^[a-zA-Z0-9._%+-]+@(spf|gt\.tech|tech)\.gov\.sg$/i,
        message: 'A valid SPF email address is required eg. user@spf.gov.sg.',
      },
    },
  },
  {
    label: 'Report Number',
    type: 'textInput',
    id: 'reportNumber',
    placeholder: 'e.g M/20231225/1234',
    alignment: 'column',
    rules: {
      required: 'Please enter a valid report number',
      pattern: {
        value: /^(?=.{13,15}$)([A-Z]{1,3}\/\d{4,8}\/\d+|ASC\/\d{4}\/\d{2}\/\d{2})$/,
        message:
          'Invalid report number. Please provide report number in the correct format e.g. M/20231225/1234 or ASC/2024/10/16.',
      },
    },
    extras: {
      keyup: (value: string) => {
        return value.toUpperCase();
      },
    },
  },
  {
    label: 'Classification',
    type: 'dropdown',
    id: 'classification',
    placeholder: 'Please Select',
    extras: { dropdownData: classifications },
    alignment: 'column',
    rules: {
      required: 'Please select an option',
    },
  },
  {
    label: 'Scam Type',
    type: 'dropdown',
    id: 'scamType',
    placeholder: 'Please Select',
    extras: { dropdownData: scamType },
    alignment: 'column',
    rules: {
      required: 'Please select an option',
    },
  },
  {
    label: 'Scam Sub-Type',
    type: 'textInput',
    id: 'scamSubType',
    optional: true,
    alignment: 'column',
  },
  {
    label: 'Purpose of Submission',
    type: 'dropdown',
    id: 'purposeOfSubmission',
    placeholder: 'Please Select',
    extras: { dropdownData: purposeOfSubmission },
    alignment: 'column',
    rules: {
      required: 'Please select an option',
    },
  },
];

export const viewFormGeneralItems: Array<FormItemInterface> = [
  {
    label: 'Police Station/Unit',
    type: 'label',
    id: 'policeStation',
  },
  {
    label: 'SPF Email Address',
    type: 'label',
    id: 'spfEmail',
  },
  {
    label: 'Report Number',
    type: 'label',
    id: 'reportNumber',
  },
  {
    label: 'Scam Type',
    type: 'label',
    id: 'scamType',
  },
  {
    label: 'Sub Scam Type',
    type: 'label',
    id: 'scamSubType',
  },
  {
    label: 'Purpose of Submission',
    type: 'label',
    id: 'remarks',
  },
];

export const createTransactionItems: Array<FormItemInterface> = [
  {
    label: 'Date of Transfer',
    type: 'calendar',
    id: 'dateOfTransfer',
    placeholder: 'dd/mm/yyyy',
    alignment: 'column',
    rules: {
      required: 'Date of transfer is required',
    },
  },
  {
    label: 'Medium of Transfer',
    type: 'dropdown',
    id: 'platform',
    alignment: 'column',
    placeholder: 'Please Select',
    extras: { dropdownData: financialExchangePlatform },
    rules: {
      required: 'Please select an option',
    },
  },
  {
    label: 'Account No/Transaction Reference',
    type: 'textInput',
    id: 'transactionReference',
    alignment: 'column',
    rules: {
      required: 'Please input',
    },
  },
  {
    label: 'Amount of Losses',
    type: 'textInput',
    id: 'amount',
    placeholder: 'e.g 1000',
    alignment: 'column',
    rules: {
      required: 'Please enter a number',
      pattern: {
        value: /^[0-9]+$/,
        message: 'Please enter a number',
      },
    },
  },
];

export const viewFormGeneraMonikerItems: Array<FormItemInterface> = [
  {
    label: 'Use of Scam Enabler',
    type: 'label',
    id: 'useOfScamEnabler',
  },
  {
    label: 'Platform',
    type: 'label',
    id: 'platform',
  },
];
