import { Transaction } from '@interface/constant';
import DynamicForm from '@components/common/DynamicForm';
import { transactionDetailsForm } from '../bankSection/bankSectionFormConfig';
import { useForm } from 'react-hook-form';
import { convertMsToYYYYMMDD } from '@utils/utils';

function calculateTotalSGD(transactions: any) {
  let total = 0;
  if (transactions?.transactions?.length > 0) {
    transactions.transactions.forEach((t: any) => {
      total += t?.amountLossInSGD || t?.amountLoss || 0;
    });
  }

  return total;
}

export function TransactionDetails({
  amtEarmarked,
  transactionHistory,
}: {
  amtEarmarked: number;
  transactionHistory: any;
}) {
  const data = {
    totalAmtScammed: `$${calculateTotalSGD(transactionHistory).toFixed(2)}`,
    amtEarmarked: `$${amtEarmarked || 0}`,
    transactions:
      transactionHistory?.transactions && Array.isArray(transactionHistory.transactions)
        ? transactionHistory.transactions.map((t: any) => {
            return {
              dateOfTransfer: convertMsToYYYYMMDD(t.dateOfTransfer),
              amountLoss: `$${t?.amountLossInSGD.toFixed(2) || t?.amountLoss?.toFixed(2) || 0} ${t?.currency === 'SGD' ? '' : `(${t?.amountLoss?.toFixed(2) + ' ' + t?.currency})`}`,
              transactionType: t.transactionType,
            };
          })
        : [],
  };
  const formMethods = useForm({
    values: data,
  });

  return (
    <>
      <p className="italic text-gray-400 mb-4">
        The breakdown is sorted with the most recent submission at the top
      </p>

      <DynamicForm
        formFields={transactionDetailsForm}
        formMethods={formMethods}
        data={data}
        isEditable={false}
      />
    </>
  );
}
