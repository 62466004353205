import { ThemeProvider } from '@emotion/react';
import { CircularProgress, createTheme, Tooltip } from '@mui/material';
import clsx from 'clsx';
import { ReactComponent as Download } from '@assets/icons/download.svg';
import { ReactComponent as Delete } from '@assets/icons/delete.svg';
import { ReactComponent as Generate } from '@assets/icons/generate.svg';
import { ReactComponent as Assign } from '@assets/icons/assign.svg';
import { ReactComponent as Logout } from '@assets/icons/logout.svg';
import { ReactComponent as Notification } from '@assets/icons/notification.svg';
import { ReactComponent as Edit } from '@assets/icons/edit.svg';
import { ReactComponent as Tick } from '@assets/icons/tick.svg';
import { ReactComponent as Cross } from '@assets/icons/cross.svg';
import { ReactComponent as Add } from '@assets/icons/add_circle.svg';
import { ReactComponent as ReportManagement } from '@assets/icons/report_management.svg';
import { ReactComponent as ActivityLog } from '@assets/icons/activity_log.svg';
import { ReactComponent as Hamburger } from '@assets/icons/hamburger.svg';
import { ReactComponent as LeftArrowWithLine } from '@assets/icons/left_arrow_with_line.svg';
import { ReactComponent as Error404 } from '@assets/icons/error_404.svg';
import { ReactComponent as Diary } from '@assets/icons/diary.svg';
import { ReactComponent as Info } from '@assets/icons/info.svg';
import { ReactComponent as DownArrow } from '@assets/icons/down_arrow.svg';
import { ReactComponent as More } from '@assets/icons/more.svg';
import { ReactComponent as MoreDarkBlue } from '@assets/icons/more-darkblue.svg';
import { ReactComponent as LeftArrow } from '@assets/icons/left_arrow_blue.svg';
import { ReactComponent as RightArrow } from '@assets/icons/right_arrow_blue.svg';

import { ReactComponent as Comment } from '@assets/icons/comment.svg';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { debounce } from 'lodash';

import AccessControl from './AccessControl';

import { Menu } from '@mui/material';
import { MouseEvent, useCallback, useRef, useState } from 'react';

interface DropdownOption {
  key: string;
  title: string;
  disabled: boolean;
  isLoading?: boolean;
  allowedPermissions?: string[];
  disabledMessage?: string;
  onClick: any;
}

interface CommonButtonGroupProps extends CommonButtonProps {
  dropdownOptions: DropdownOption[];
  allowedPermissions?: string[];
  icon?: string;
}

type CommonButtonProps = {
  isDisabled?: boolean;
  variant: string;
  title: string;
  onClick?: any;
  isLoading?: boolean;
  onMouseEnter?: any;
  onMouseLeave?: any;
  disabledDisplay?: string;
  allowedPermissions?: string[];
  renderNoAccess?: any;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const buttonStyles = 'flex h-10 items-center rounded-md px-4 font-semibold transition-colors';
const focusStyles =
  'focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600';
const activeUnderline = 'active:text-blue-600 active:underline';
const hoverUnderline = 'hover:text-blue-400 hover:underline';

export const CommonButtonGroup = ({
  dropdownOptions,
  allowedPermissions,
  icon,
  ...props
}: CommonButtonGroupProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  props.children = anchorEl ? <ExpandLess /> : <ExpandMore />;
  const isOpen = Boolean(anchorEl);

  const getButtonClass = () => {
    if (props.variant === 'primary') {
      if (isOpen) {
        return 'bg-blue-600 text-white';
      } else {
        return 'bg-blue-500 text-white';
      }
    } else {
      if (isOpen) {
        return 'bg-blue-400 text-white';
      } else {
        return 'bg-blue-300 text-blue-500';
      }
    }
  };
  const buttonColorClass = getButtonClass();

  return (
    <>
      <AccessControl
        type="component"
        allowedPermissions={allowedPermissions}
        renderNoAccess={() => <></>}
      >
        {icon === undefined && (
          <CommonButton
            {...props}
            onClick={handleClick}
            className={`${props.className} ${buttonColorClass}`}
          />
        )}
        {icon !== undefined && (
          <IconButton icon={icon} isDisabled={false} onClick={handleClick} type="button" />
        )}
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={{ '& .MuiList-padding': { padding: 0 } }}
        >
          {dropdownOptions.map((option, index) => (
            <Tooltip
              title={option.disabled ? option?.disabledMessage || '' : ''}
              key={index}
              placement="right"
              componentsProps={{
                tooltip: {
                  sx: {
                    fontSize: '14px',
                  },
                },
              }}
            >
              <>
                <CommonButton
                  isDisabled={option.disabled}
                  variant={`${props.variant}-group`}
                  className={clsx(
                    `font-bold text-md w-full rounded-none text-left ${props.className ? props.className : ''}`,
                    {
                      'bg-blue-300 text-[#93A4A9] flex h-10 items-center rounded-md px-4  cursor-not-allowed ':
                        option.disabled && !props.className,
                      'text-blue-500': !option.disabled && !props.className,
                    },
                  )}
                  title={option.title}
                  onClick={(e: any) => option.onClick(e)}
                  isLoading={option.isLoading}
                  allowedPermissions={option.allowedPermissions}
                />
              </>
            </Tooltip>
          ))}
        </Menu>
      </AccessControl>
    </>
  );
};
export const CommonButton = ({
  isDisabled,
  variant,
  title,
  onClick,
  isLoading,
  onMouseEnter,
  onMouseLeave,
  allowedPermissions,
  renderNoAccess,
  ...props
}: CommonButtonProps) => {
  const getButtonStyle = () => {
    const baseStyles = `${isDisabled && variant !== 'text' && !variant.includes('group') ? `bg-grey-300 text-white cursor-not-allowed ${buttonStyles}` : ''} 
    ${isDisabled && variant === 'text' ? 'text-grey-300' : ''} 
    ${!(isDisabled || props.disabled) && variant === 'custom' ? `${focusStyles} ${buttonStyles}` : ''} 
    ${!(isDisabled || props.disabled) && variant.includes('secondary') ? `bg-blue-300 text-blue-500 active:bg-blue-300 active:ring-2 active:ring-blue-600 hover:bg-blue-400 hover:text-white ${focusStyles} ${buttonStyles}` : ''} 
    ${!(isDisabled || props.disabled) && variant.includes('primary') ? `bg-blue-500 text-white active:bg-blue-300 active:ring-2 active:ring-blue-600 active:text-blue-600 hover:opacity-85 ${buttonStyles}` : ''} 
    ${!(isDisabled || props.disabled) && variant === 'warning' ? `bg-red text-white active:opacity-80 active:border-red active:text-red hover:opacity-80 ${buttonStyles}` : ''} 
    ${!(isDisabled || props.disabled) && variant === 'text' ? `text-blue-500 font-bold ${activeUnderline} ${hoverUnderline} ${focusStyles}` : ''} 
    ${!(isDisabled || props.disabled) && variant === 'text-blue' ? `px-0 text-blue-400 font-bold ${activeUnderline} ${hoverUnderline} ${focusStyles}` : ''} 
    ${!(isDisabled || props.disabled) && variant === 'underline-text' ? `text-black underline ${activeUnderline} ${hoverUnderline} ${focusStyles}` : ''} 
    ${!(isDisabled || props.disabled) && variant === 'underline-blue-text' ? `text-blue underline ${activeUnderline} ${hoverUnderline} ${focusStyles}` : ''}`;

    return `${baseStyles} ${props.className}`;
  };
  const debouncedOnClick = onClick
    ? debounce(onClick, 500, { leading: true, trailing: false })
    : onClick;

  return (
    <AccessControl
      type="component"
      allowedPermissions={allowedPermissions}
      renderNoAccess={renderNoAccess}
    >
      <div
        className="flex align-middle"
        onMouseEnter={() => {
          if (onMouseEnter) onMouseEnter();
        }}
        onMouseLeave={() => {
          if (onMouseLeave) onMouseLeave();
        }}
      >
        <button
          {...props}
          className={getButtonStyle()}
          onClick={debouncedOnClick}
          disabled={isDisabled}
        >
          {isLoading ? (
            <div className={`flex flex-row items-center gap-2`}>
              <ThemeProvider theme={theme}>
                <CircularProgress size={24} color="primary" />{' '}
              </ThemeProvider>

              <div className="flex flex-row">
                <p className="text-blue-400">{title}</p>
              </div>
            </div>
          ) : (
            <div className={`flex flex-row items-center`}>
              {title}
              {props.children}
            </div>
          )}
        </button>
      </div>
    </AccessControl>
  );
};

export const theme = createTheme({
  palette: {
    primary: {
      main: '#6FB8C9', // This is the hex value of Tailwind's blue-400
    },
  },
});

export function LoadingButtonComponent({
  isLoading,
  title,
  variant,
  onClick,
}: {
  isLoading: boolean;
  title: string;
  variant?: string;
  onClick: any;
}) {
  return (
    <button
      onClick={onClick}
      className={clsx(`flex items-center justify-center px-4 py-2 text-white rounded`, {
        'bg-blue-200 cursor-not-allowed': !variant && isLoading,
        'bg-blue-600 hover:bg-blue-300': !variant && !isLoading,
        'bg-red hover:opacity-20': variant && variant === 'red' && !isLoading,
        'bg-red cursor-not-allowed': variant && variant === 'red' && isLoading,
      })}
      disabled={isLoading}
    >
      {isLoading ? (
        <div className="flex flex-row items-center gap-2">
          <ThemeProvider theme={theme}>
            <CircularProgress size={24} color="primary" />{' '}
          </ThemeProvider>

          <p className="text-blue-400">{title}</p>
        </div>
      ) : (
        title
      )}
    </button>
  );
}

export const IconButton = ({
  isDisabled,
  icon,
  onClick,
  isLoading,
  size = '25px',
  type,
  className = '',
  allowedPermissions,
  renderNoAccess,
}: {
  isDisabled: boolean;
  icon: string;
  isLoading?: boolean;
  size?: string;
  onClick?: (event?: any) => void;
  type?: 'submit' | 'button';
  className?: string;
  allowedPermissions?: string[];
  renderNoAccess?: any;
}) => {
  return (
    <>
      <AccessControl
        type="component"
        allowedPermissions={allowedPermissions}
        renderNoAccess={renderNoAccess}
      >
        {!isLoading && (
          <button
            disabled={isDisabled}
            onClick={onClick}
            type={type ? type : 'button'}
            className={clsx(className, {
              'opacity-20': isDisabled,
            })}
          >
            {icon === 'download' && <Download width={size} height={size} />}
            {icon === 'delete' && <Delete width={size} height={size} />}
            {icon === 'generate' && <Generate width={size} height={size} />}
            {icon === 'assign' && <Assign width={size} height={size} />}
            {icon === 'logout' && <Logout width={size} height={size} />}
            {icon === 'notification' && <Notification width={size} height={size} />}
            {icon === 'edit' && <Edit width={size} height={size} />}
            {icon === 'tick' && <Tick width={size} height={size} />}
            {icon === 'cross' && <Cross width={size} height={size} />}
            {icon === 'add' && <Add width={size} height={size} />}
            {icon === 'report_management' && <ReportManagement width={size} height={size} />}
            {icon === 'activity_log' && <ActivityLog width={size} height={size} />}
            {icon === 'comment' && <Comment width={size} height={size} />}
            {icon === 'hamburger' && <Hamburger width={size} height={size} />}
            {icon === 'left_arrow_with_line' && <LeftArrowWithLine width={size} height={size} />}
            {icon === 'diary' && <Diary width={size} height={size} />}
            {icon === 'error404' && <Error404 width={size} height={size} />}
            {icon === 'info' && <Info width={size} height={size} />}
            {icon === 'downArrow' && <DownArrow width={size} height={size} />}
            {icon === 'more' && <More width={size} height={size} />}
            {icon === 'more-darkblue' && <MoreDarkBlue width={size} height={size} />}
            {icon === 'left-arrow' && <LeftArrow width={size} height={size} />}
            {icon === 'right-arrow' && <RightArrow width={size} height={size} />}
          </button>
        )}
        {isLoading && (
          <ThemeProvider theme={theme}>
            <CircularProgress size={24} color="primary" />{' '}
          </ThemeProvider>
        )}
      </AccessControl>
    </>
  );
};
