import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetScamEnablersByIdV2Query } from '@api/scamEnablerSlice';
import { getSessionUserFromAccessToken } from '@utils/utils';
import { useSendProductionOrderQueue } from '@api/productionOrderSlice';

export const useMonikersDetailedPage = () => {
  const params = useParams();
  const [currentRole, setCurrentRole] = useState('');
  const [currentUser, setCurrentUser] = useState('');
  const sendProductionOrderQueue = useSendProductionOrderQueue();

  const { refetch, data, isSuccess, isError, isLoading } = useGetScamEnablersByIdV2Query(
    {
      id: params.id,
    },
    {},
  );

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    const checkAssignment = async () => {
      let user = await getSessionUserFromAccessToken();
      setCurrentUser(user);
      if (data?.scamEnabler?.assignment) {
        let userRole = data.scamEnabler.assignment.find(
          (assignment: any) => assignment.userId === user.userId,
        );

        if (userRole) {
          setCurrentRole(userRole.role);
        } else {
          setCurrentRole('');
        }
      }
    };
    checkAssignment();
  }, [data]);

  return {
    params,
    sendProductionOrderQueue,
    currentRole,
    currentUser,
    refetch,
    data,
    isSuccess,
    isError,
    isLoading,
  };
};
