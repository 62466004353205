import { designations } from '@interface/constant';
import { FormItemInterface } from '@interface/dynamicFormInterface';

export const userFormGeneralItems: Array<FormItemInterface> = [
  {
    label: 'Email',
    type: 'textInput',
    id: 'email',
    placeholder: 'user@spf.gov.sg',
    alignment: 'column',
    disabled: true,
    rules: {
      required: 'Please enter a valid SPF email',
      pattern: {
        value: /^[a-zA-Z0-9._%+-]+@(spf|gt\.tech|tech).gov.sg$/i,
        message: 'A valid SPF email address is required eg. user@spf.gov.sg.',
      },
    },
  },
  {
    label: 'First Name',
    type: 'textInput',
    id: 'firstName',
    placeholder: 'Daniel',
    alignment: 'column',
    rules: {
      required: 'Please enter a valid First Name',
    },
  },
  {
    label: 'Last Name',
    type: 'textInput',
    id: 'lastName',
    placeholder: 'Tan',
    alignment: 'column',
    rules: {
      required: 'Please enter a valid First Name',
    },
  },
  {
    label: 'Phone No.',
    id: 'contactNumber',
    type: 'telephoneInput',
    alignment: 'column',
  },
  {
    label: 'Designation',
    id: 'designation',
    type: 'dropdown',
    placeholder: 'Please select designation',
    extras: { dropdownData: designations },
    alignment: 'column',
    rules: {
      required: 'Please select a designation',
    },
  },
  {
    label: 'Reset password',
    type: 'label',
    id: 'resetPassword',
    alignment: 'column',
    className: 'my-4',
  },
  {
    label: 'Current Password',
    type: 'password',
    id: 'currentPassword',
    placeholder: 'Current Password',
    alignment: 'column',
    rules: {
      minLength: {
        value: 8,
        message: 'Please enter at least 8 characters',
      },
    },
  },
  {
    label: 'New Password',
    type: 'password',
    id: 'newPassword',
    placeholder: 'New Password',
    alignment: 'column',
    displayPasswordRequirements: true,
    rules: {
      minLength: {
        value: 8,
      },
    },
  },
  {
    label: 'Confirm Password',
    type: 'password',
    id: 'confirmPassword',
    placeholder: 'Confirm Password',
    alignment: 'column',
    rules: {
      minLength: {
        value: 8,
        message: 'Please enter at least 8 characters',
      },
    },
  },
];
