import { apiSlice } from './apiSlice';

export const financialInstituteSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllFinancialInstituteEntries: builder.query({
      query: (request) => {
        console.log('filters', request.params.filters);
        return {
          url: '/v2/financialinstitutes',
          params: {
            page: request.params.page,
            size: request.params.size,
            search: request.params.search,
            sort_by: request.params.sort_by,
            order_by: request.params.order_by,
            platform: request.params.filters?.platform,
            outcome: request.params.filters?.outcome,
            dutyOfficer: request.params.filters?.dutyOfficer || '',
            created_date_from: request.params.filters?.created_date_from,
            created_date_to: request.params.filters?.created_date_to,
            // scam_type: request.params.filters?.scam_type,
            // da1: request.params.filters?.da1,
            // da2: request.params.filters?.da2,
          },
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    getFinancialInstituteById: builder.query({
      query: (params) => {
        return {
          url: `/v2/financialinstitute/${params.id}`,
        };
      },
      transformResponse: (response: any) => {
        console.log('response', response);
        return response;
      },
    }),

    updateFinancialInstitute: builder.mutation({
      query: (params) => {
        return {
          method: 'PUT',
          body: [params],
          url: `v2/financialinstitutes`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),

    updateFinancialInstitutes: builder.mutation({
      query: (params) => {
        return {
          method: 'PUT',
          body: params,
          url: `v2/financialinstitutes`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),

    updateFinancialInstituteTransactions: builder.mutation({
      query: (params) => {
        return {
          method: 'PUT',
          body: params,
          url: `v2/financialinstitute/transactions`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),

    assignUserToFinancialInstitute: builder.mutation({
      query: (params) => {
        return {
          method: 'PUT',
          body: params,
          url: 'v2/financialinstitute/assign',
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
  }),
});

export const {
  useGetAllFinancialInstituteEntriesQuery,
  useGetFinancialInstituteByIdQuery,
  useUpdateFinancialInstituteMutation,
  useUpdateFinancialInstituteTransactionsMutation,
  useUpdateFinancialInstitutesMutation,
  useAssignUserToFinancialInstituteMutation,
} = financialInstituteSlice;
