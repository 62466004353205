import Modal from '@mui/material/Modal';
import { useState } from 'react';
import { BACKEND_URL } from '@environment/AppEnvironment';
import ModalLayout from '../layouts/ModalLayout';
import UploadScreenshotModal from '@pages/reports/modals/UploadScreenshotModal';
import { useForm } from 'react-hook-form';
import { CommonButton, IconButton } from './CommonButton';
import {
  useDeleteProactiveScamEnablersAttachmentV2Mutation,
  useDeleteScamEnablerAttachmentV2Mutation,
  useUploadProactiveScamEnablersAttachmentV2Mutation,
  useUploadScamEnablerAttachmentV2Mutation,
} from '@api/scamEnablerSlice';
import { useDispatch } from 'react-redux';
import { showAlert } from '@store/alertSlice';
import cloneDeep from 'lodash.clonedeep';

const ScreenshotsModal = ({
  attachments,
  scamEnablerId,
  isEditable,
  attachmentPath,
  updateAPI,
  callback,
  data,
  allowPaste,
}: {
  attachments: Array<string>;
  scamEnablerId: string;
  isEditable?: boolean;
  attachmentPath: string;
  updateAPI: string;
  callback?: any;
  data: any;
  allowPaste?: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const [openFile, setOpenFile] = useState('');
  const [displayFileName, setDisplayFileName] = useState('');

  const formMethods = useForm();
  const [showUploadScreenshotModal, setShowUploadScreenshotModal] = useState(false);
  const [uploadScamEnablerAttachment] = useUploadScamEnablerAttachmentV2Mutation();
  const [deleteScamEnablerAttachment] = useDeleteScamEnablerAttachmentV2Mutation();

  const [uploadProactiveScamEnablerAttachment] =
    useUploadProactiveScamEnablersAttachmentV2Mutation();
  const [deleteProactiveScamEnablerAttachment] =
    useDeleteProactiveScamEnablersAttachmentV2Mutation();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState<Record<number, boolean>>({});
  const dispatch = useDispatch();

  const handleUploadScreenshot = async () => {
    setIsLoading(true);
    let attachmentData = formMethods.getValues();

    let tempAttachments = cloneDeep(attachmentData.attachments);
    tempAttachments.forEach((attachment: any) => {
      if (updateAPI === 'uploadProactiveScamEnablerAttachmentV2') {
        let splitFileName = attachment.fileName.split('.');
        attachment.fileName = `${data.scamEnablerUserId}.${splitFileName[splitFileName.length - 1]}`;
      }
      attachment.fileContent = attachment.fileContent.replace(/^data:.+;base64,/, '');
    });
    let response: any = {};
    if (updateAPI === 'uploadProactiveScamEnablerAttachmentV2') {
      response = await uploadProactiveScamEnablerAttachment({
        proactiveScamEnablerId: scamEnablerId,
        attachments: tempAttachments,
      });
    } else {
      response = await uploadScamEnablerAttachment({
        scamEnablerId: scamEnablerId,
        attachments: tempAttachments,
      });
    }

    if (response && !response.error) {
      dispatch(
        showAlert({
          type: 'success',
          message: `Upload file Successful`,
        }),
      );
      setShowUploadScreenshotModal(false);
      if (callback) callback();
    }
    setIsLoading(false);
  };

  const handleDeleteScreenshot = async (filename: string, index: number) => {
    let response: any = {};
    setIsLoadingDelete((prevState) => ({ ...prevState, [index]: true }));
    if (updateAPI === 'uploadProactiveScamEnablerAttachmentV2') {
      response = await deleteProactiveScamEnablerAttachment({
        proactiveScamEnablerId: scamEnablerId,
        attachment: filename,
      });
    } else {
      response = await deleteScamEnablerAttachment({
        scamEnablerId: scamEnablerId,
        attachment: filename,
      });
    }

    if (response && !response.error) {
      dispatch(
        showAlert({
          type: 'success',
          message: `Delete file Successful`,
        }),
      );
      if (callback) callback();
    }
    setIsLoadingDelete((prevState) => ({ ...prevState, [index]: false }));
  };
  const handleOpen = (filename: string) => {
    if (filename && filename.length > 0) {
      const fileExtension = filename.split('.').pop()?.toLowerCase() ?? ''; // Provide a default value for fileExtension
      const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];

      let tempFileName = filename;
      let splitFile = filename.split('/');
      let tempDisplayFileName = '';
      if (splitFile.length > 1) {
        tempFileName = splitFile[0] + '/' + encodeURIComponent(splitFile[1]);
        tempDisplayFileName = splitFile[1];
      } else {
        tempFileName = scamEnablerId + '/' + encodeURIComponent(tempFileName);
        tempDisplayFileName = tempFileName;
      }

      if (!imageExtensions.includes(fileExtension)) {
        const a = document.createElement('a');

        a.href = `${BACKEND_URL}${attachmentPath}${tempFileName}`;
        a.target = '_blank';
        a.rel = 'noopener noreferrer';
        // Append the anchor element to the document
        document.body.appendChild(a);

        // Trigger a click event on the anchor element
        a.click();

        // Remove the anchor element from the document
        document.body.removeChild(a);
      } else {
        setOpen(true);
        setDisplayFileName(tempDisplayFileName);
        setOpenFile(tempFileName);
      }
    }
  };
  const handleClose = () => setOpen(false);

  return (
    <div className="flex flex-col">
      {attachments &&
        attachments.map((filename, index) => {
          return (
            <div key={index} className="break-word group">
              <div className="flex flex-row gap-2 py-1">
                <CommonButton
                  isLoading={false}
                  variant="text-blue"
                  title={filename.split('/').length > 1 ? filename.split('/')[1] : filename}
                  onClick={() => handleOpen(filename)}
                  className="text-left"
                />
                {isEditable && (
                  <IconButton
                    isDisabled={false}
                    icon="delete"
                    onClick={() => handleDeleteScreenshot(filename, index)}
                    isLoading={isLoadingDelete[index]}
                    type="button"
                    className="hidden group-hover:inline-flex"
                  />
                )}
              </div>
              <Modal open={open} onClose={handleClose}>
                <ModalLayout
                  title={displayFileName}
                  handleCloseModal={handleClose}
                  alignment="center"
                  size="large"
                >
                  <img src={`${BACKEND_URL}${attachmentPath}${openFile}`} alt={openFile} />
                </ModalLayout>
              </Modal>
            </div>
          );
        })}
      {isEditable && (
        <form>
          <IconButton
            isDisabled={false}
            icon="add"
            onClick={() => setShowUploadScreenshotModal(true)}
            isLoading={false}
            type="button"
          />

          {showUploadScreenshotModal && (
            <UploadScreenshotModal
              id={`attachments`}
              isOpen={showUploadScreenshotModal}
              onClose={() => setShowUploadScreenshotModal(false)}
              formMethods={formMethods}
              onUploadScreenshot={handleUploadScreenshot}
              isLoading={isLoading}
              allowPaste={allowPaste}
            />
          )}
        </form>
      )}
    </div>
  );
};

export default ScreenshotsModal;
