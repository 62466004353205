import { useMonikersDetailedPage } from './useMonikersDetailedPage';
import Breadcrumbs from '@components/common/Breadcrumbs';
import PortfoliosMonikerDetailsOverviewComponent from './PortfoliosMonikerDetailsOverviewComponent';
import PortfoliosMonikerRelatedReports from './PortfoliosMonikerRelatedReports';
import { CommonButton } from '@components/common/CommonButton';
import { AssignButton, GenerateProductionOrderButton } from '@pages/portfolios/Buttons';
import { useState } from 'react';
import AssessmentFindingsComponent from './AssessmentFindings/AssessmentFindingsComponent';
import PortfoliosMonikerDetailsComponent from './PortfolioMonikerDetailsComponent';

const PortfolioMonikerDetailsPage = () => {
  const { params, currentRole, currentUser, refetch, data, isLoading, sendProductionOrderQueue } =
    useMonikersDetailedPage();

  const [isSendPOQueueLoading, setIsSendPOQueueLoading] = useState(false);
  const onSubmitPOQueue = async () => {
    setIsSendPOQueueLoading(true);
    await sendProductionOrderQueue([data.scamEnabler._id], 'monikerView');
    setIsSendPOQueueLoading(false);
    refetch();
  };
  return (
    <>
      <div className="w-100 bg-grey-200 p-4 flex flex-row justify-between items-center">
        <Breadcrumbs
          breadcrumbs={[
            { label: 'Monikers', href: '/admin/portfolios' },
            {
              label: data?.scamEnabler?.scamEnablerUserId,
              href: `/admin/portfolios/monikers/${params.id}`,
              active: true,
            },
          ]}
        />
        {
          <div className="flex flex-row gap-4">
            {/* <CommonButton
              isDisabled={false}
              variant="text"
              title={`Download`}
              onClick={handleOpenQueuePOModal}
            /> */}
            {data && data.scamEnabler && !data.scamEnabler.assignment && (
              <AssignButton
                selectedScamEnablers={[data.scamEnabler]}
                showSelectedScamEnablers={false}
                callback={refetch}
                isDisabled={false}
              />
            )}

            {data &&
              currentRole &&
              (currentRole === 'da1' || currentRole === 'da2') &&
              ((data?.scamEnabler?.findings?.monikerPreSubmitChecklist2?.checklist
                ?.checklist2_operationalRequirements_Q1 &&
                data?.scamEnabler?.findings?.monikerPreSubmitChecklist2?.checklist
                  ?.checklist2_operationalRequirements_Q2 &&
                data?.scamEnabler?.findings?.monikerPreSubmitChecklist2?.checklist
                  ?.checklist2_legalRequirements_Q1) ||
                data?.scamEnabler?.findings?.monikerPreSubmitChecklist1?.checklist?.channel ===
                  'Community Guideline') && (
                <>
                  <CommonButton
                    isDisabled={(currentRole !== 'da2' && currentRole !== 'da1') || (data?.scamEnabler?.channel ==="OCHA" && currentRole !== 'da2')}
                    variant="secondary"
                    title={`Send to Directions Queue`}
                    onClick={onSubmitPOQueue}
                    isLoading={isSendPOQueueLoading}
                  />

                  <GenerateProductionOrderButton
                    scamEnablerData={[data.scamEnabler]}
                    callback={refetch}
                    type="text"
                  />
                </>
              )}
          </div>
        }
      </div>

      <div className="box-border h-full flex overflow-hidden rounded-lg bg-white p-4">
        <section className="box-border w-1/3 rounded-lg bg-white ">
          <div className="box-border flex-row h-full bg-grey-100 rounded-lg p-6">
            <PortfoliosMonikerDetailsOverviewComponent
              data={data}
              refetch={refetch}
              isLoading={isLoading}
              isEditable={currentRole === 'da1' || currentRole === 'da2'}
              isDirectionQueueMonikerModal={false}
            />
            <PortfoliosMonikerRelatedReports data={data} isLoading={isLoading} />
          </div>
        </section>
        <section className="box-border w-1/3 bg-white rounded-lg  pl-4">
          <div className="box-border h-full flex flex-col bg-grey-100 rounded-lg p-6">
            <PortfoliosMonikerDetailsComponent
              data={data}
              refetch={refetch}
              isLoading={isLoading}
              isEditable={currentRole === 'da1' || currentRole === 'da2'}
              isDirectionQueueMonikerModal={false}
            ></PortfoliosMonikerDetailsComponent>
          </div>
        </section>
        <section className="box-border w-1/3  rounded-lg pl-4 bg-white min-h-0 ">
          <AssessmentFindingsComponent
            data={data}
            callback={refetch}
            currentRole={currentRole}
            currentUser={currentUser}
            isLoading={isLoading}
            scamEnablerId={params.id}
          />
        </section>
      </div>
    </>
  );
};

export default PortfolioMonikerDetailsPage;
