import Breadcrumbs from '@components/common/Breadcrumbs';
import { AssignSingleButton, GenerateProductionOrderBankButton } from '@pages/portfolios/Buttons';
import { useBankDetailedPage } from './useBankDetailedPage';
import { BankDetailedOverview } from './BankDetailedOverview';
import { ProductionOrdersIssued } from '@pages/reports/ReportsDetailedPage/bankSection/ProductionOrdersIssued';
import TransactionDetails from './TransactionDetails';
import { useEffect, useMemo, useState } from 'react';
import { LinkedPaynowBank } from './LinkedPaynowBank';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { AmountMoneyCard } from './components/AmountMoneyCard';
import { Transaction } from '@interface/constant';
import SeizureDetails from './SeizureDetails';

const PortfolioMonikerDetailsPage = () => {
  const { params, reportId, refetch, data, myUser } = useBankDetailedPage();
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    console.log(data);
  }, [data]);

  const isAssignedToMe = useMemo(() => {
    if (data && data.financialInstitute) {
      return data?.financialInstitute?.dutyOfficer?.userId == myUser.userId;
    }

    return false;
  }, [data]);

  const getReportNumberFromUpdatedTransaction = (reportId: string) => {
    if (!reportId) return '';
    if (data?.financialInstitute?.updatedTransactions?.length > 0) {
      return (
        data?.financialInstitute?.updatedTransactions.find(
          (transaction: any) => transaction.reportId == reportId,
        )?.reportNumber || ''
      );
    }
    return '';
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    console.log(newValue);
    setTabValue(newValue);
  };

  function getTotalFromTransactionsAcrossAllReports(transactions: Array<any>) {
    let total = 0;
    if (transactions?.length > 0) {
      transactions.map((report: any) =>
        report.transactions.forEach((transaction: Transaction) => {
          total += transaction?.amountLossInSGD || 0;
        }),
      );
    }

    return total;
  }

  function getSeized(seizedAmount: Array<any>) {
    // Group the seizedAmount by poReferenceNo
    const groupedSeizedAmount = seizedAmount.reduce((acc: any, seized: any) => {
      const poReferenceNo = seized.poReferenceNo;
      if (!acc[poReferenceNo]) {
        acc[poReferenceNo] = [];
      }
      acc[poReferenceNo].push(seized);
      return acc;
    }, {});

    console.log('groupedSeizedAmount:', groupedSeizedAmount);

    // Find the group with the latest bankDetailsRetrievedDate
    let latestGroup: any = null;
    let latestDate: Date | null = null;
    for (const group in groupedSeizedAmount) {
      let groupSeizedAmount = groupedSeizedAmount[group];
      groupSeizedAmount.sort((a: any, b: any) => {
        return b.bankDetailsRetrievedDate - a.bankDetailsRetrievedDate;
      });
      console.log('sortedGroupSeizedAmount:', groupSeizedAmount);

      const bankDetailsRetrievedDate = groupSeizedAmount[0].bankDetailsRetrievedDate;
      if (!latestDate || bankDetailsRetrievedDate > latestDate) {
        latestGroup = groupSeizedAmount;
        latestDate = bankDetailsRetrievedDate;
      }
    }

    // Calculate the total seized amount for the latest group
    let totalSeized = 0;
    if (latestGroup) {
      latestGroup.forEach((seized: any) => {
        totalSeized += seized.amountInSGD;
      });
    }

    return totalSeized;
  }

  function getEarmarked(earmarkedAmount: Array<any>) {
    if (earmarkedAmount && earmarkedAmount.length === 0) return 0;
    return earmarkedAmount.reduce((accum: number, nextT: any) => accum + nextT.amountInSGD, 0);
  }

  return (
    <div className="h-full">
      <>
        <div className="w-100 bg-grey-200 p-4 flex flex-row justify-between items-center">
          <Breadcrumbs
            breadcrumbs={[
              { label: 'Banks', href: '/admin/portfolios' },
              {
                label: `${data?.financialInstitute.platform || ''} ${data?.financialInstitute.accountNumber || ''}`,
                href: reportId
                  ? `/admin/portfolios/banks?reportId=${reportId}`
                  : '/admin/portfolios/banks',
                active: true,
              },
            ]}
          />
          {data && isAssignedToMe && (
            <GenerateProductionOrderBankButton
              financialInstituteData={data}
              reportNumber={getReportNumberFromUpdatedTransaction(reportId || '')}
              callback={refetch}
            />
          )}
          {data && !isAssignedToMe && (
            <AssignSingleButton
              selected={data?.financialInstitute}
              type="text-button"
              callback={refetch}
            />
          )}
        </div>
        <div className="box-border h-full flex rounded-lg bg-white p-4 ">
          {/* Overview */}
          <section className="box-border w-[25%] h-full flex flex-col rounded-lg bg-white mb-8">
            <BankDetailedOverview
              data={data?.financialInstitute}
              refetch={refetch}
              isAssignedToMe={isAssignedToMe}
            />
          </section>

          {/* Transaction details */}
          <section className="box-border  w-[50%] rounded-lg bg-white mb-8 pl-4 ">
            <div className="box-border flex-row h-full bg-grey-100 rounded-lg p-6">
              {/* Header */}
              <h1 className="font-bold text-lg text-blue-500 pb-4">Monetary Details</h1>
              {/* Cards for total */}
              <div className="flex flex-wrap gap-3 mb-6">
                <AmountMoneyCard
                  title="Total Amount Scammed (SGD)"
                  amount={getTotalFromTransactionsAcrossAllReports(
                    data?.financialInstitute?.updatedTransactions,
                  )}
                  isLoading={!data}
                />
                <AmountMoneyCard
                  title="Total Amount Earmarked (SGD)"
                  amount={getEarmarked(data?.financialInstitute?.earmarkedAmount || []) || 0}
                  isLoading={!data}
                />
                <AmountMoneyCard
                  title="Latest Amount Seized (SGD)"
                  amount={getSeized(data?.financialInstitute?.seizedAmount || []) || 0}
                  isLoading={!data}
                />
              </div>
              {data && (
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  className="mb-4"
                  sx={{
                    '.MuiTabs-flexContainer': {
                      justifyContent: 'space-around',
                      flexWrap: 'wrap',
                    },
                    '& .MuiTab-root': {
                      color: 'var(--blue-400)',
                      flex: 1,
                      wordWrap: 'break-word',
                    },
                    '& .MuiTab-root.Mui-selected': {
                      color: 'var(--blue-500)',
                    },
                    '.MuiTabs-indicator': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  <Tab
                    label="Transactions by Reports"
                    sx={{
                      textTransform: 'none',
                      fontWeight: 700,
                      backgroundColor: tabValue === 0 ? 'var(--blue-200)' : 'transparent',
                      borderRadius: '5px',
                    }}
                  />
                  <div className="bg-black w-px m-1" />
                  <Tab
                    label="Seizure and Earmarked Amounts"
                    sx={{
                      textTransform: 'none',
                      fontWeight: 700,
                      backgroundColor: tabValue === 2 ? 'var(--blue-200)' : 'transparent',
                      borderRadius: '5px',
                    }}
                  />
                </Tabs>
              )}
              {!data && <div className="animate-pulse bg-grey-300 w-full h-10 rounded-lg"></div>}

              {tabValue === 0 && (
                <TransactionDetails
                  data={data}
                  mainReportId={reportId || ''}
                  refetch={refetch}
                  isAssignedToMe={isAssignedToMe}
                />
              )}
              {tabValue === 2 && (
                <SeizureDetails data={data} refetch={refetch} isAssignedToMe={isAssignedToMe} />
              )}
            </div>
          </section>

          <div className="box-border  w-[25%] flex flex-col gap-4 justify-around rounded-lg bg-white mb-8 pl-4 ">
            {/* List of PayNow Linked */}
            <section className="bg-grey-100 rounded-lg p-6">
              <h1 className="font-bold text-lg text-blue-500 pb-4 mb-2">List of PayNow Linked</h1>

              {data && <LinkedPaynowBank paynowData={data?.financialInstitute.paynowNumbers} />}
            </section>

            <section className="w-full bg-grey-100 rounded-lg p-6 flex-1">
              <h1 className="font-bold text-lg text-blue-500 pb-4">Production Orders Issued</h1>

              {data?.financialInstitute?.productionOrders &&
              data?.financialInstitute?.productionOrders.length > 0 ? (
                <ProductionOrdersIssued orders={data?.financialInstitute?.productionOrders} />
              ) : (
                <p className="italic text-xs text-gray-500">No production orders issued</p>
              )}
            </section>
          </div>
        </div>
      </>
    </div>
  );
};

export default PortfolioMonikerDetailsPage;
