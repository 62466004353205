import ReportTable from './ReportTable';
import HeaderComponent from '@components/common/HeaderComponent';

export function ReportsMainPage() {
  return (
    <div className="">
      <HeaderComponent title={'Reports'} />

      <ReportTable />
      {/* {selectedTab === 1 && <Table platforms="Whatsapp" />} */}
      <div className="mt-5 flex w-full justify-center"></div>
    </div>
  );
}

export default ReportsMainPage;
