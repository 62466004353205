import type { UploadFile, UploadProps } from 'antd';
import { Upload } from 'antd';
import { ReactComponent as ImageUpload } from '@assets/icons/file_upload_large.svg';
import { Controller } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { ReactComponent as Warning } from '@assets/icons/warning.svg';
import { ReactComponent as Delete } from '@assets/icons/delete.svg';
import { CustomFile } from '@interface/dynamicFormInterface';
import { Image } from 'image-js';
import { CommonButton } from './CommonButton';
import { getBase64 } from '@utils/utils';
import { CircularProgress } from '@mui/material';

const { Dragger } = Upload;

const FileUpload = ({
  control,
  id,
  setValue,
  getValues,
  onClose,
  fileType = [
    'image/png',
    'image/jpeg',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf',
  ], // default
  maxFiles = 3, // default
  callback,
  validation,
  isLoading = false,
  allowPaste = false,
  required = false,
}: {
  control: any;
  id: string;
  setValue: any;
  getValues: any;
  onClose?: any;
  fileType?: Array<string>;
  maxFiles?: number;
  callback?: any;
  validation?: any;
  isLoading?: boolean;
  allowPaste?: boolean;
  required?: boolean;
}) => {
  const [fileList, setFileList] = useState<CustomFile[]>([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [isFileUploading, setIsFileUploading] = useState(false);

  const props: UploadProps = {
    name: 'file',
    multiple: true,
    beforeUpload: async (file: UploadFile) => {
      const allowedType = validateFileType(file, fileType);
      const fileNameRegex = /^[^\\/:*?"<>|,]+\.([a-zA-Z]{2,4})$/;

      if (!allowedType) {
        setErrorMsg('Incompatible file type.');
        return Upload.LIST_IGNORE;
      } else if (file.size && file.size > 3000000) {
        setErrorMsg('File size exceeds 3MB.');
        return Upload.LIST_IGNORE;
      } else if (fileList.length === maxFiles) {
        setErrorMsg(`You can only upload a maximum of ${maxFiles} files.`);
        return Upload.LIST_IGNORE;
      } else if (!fileNameRegex.test(file.name)) {
        setErrorMsg('Invalid file name.');
        return Upload.LIST_IGNORE;
      }
      if (validation) {
        let errorMessage = await validation(file);
        if (errorMessage) {
          setErrorMsg(errorMessage);
          return Upload.LIST_IGNORE;
        }
      }
      setErrorMsg('');
      getUploadData(file);
      return false;
    },
    onRemove: (file: UploadFile) => {
      setFileList((prev) => {
        const updatedList = prev.filter((f) => f.fileName !== file.name);
        setValue(id, updatedList);

        return updatedList;
      });
    },
  };

  const validateFileType = ({ type, name }: UploadFile, allowedTypes?: string[]) => {
    if (!allowedTypes) {
      return true;
    }
    if (type) {
      return allowedTypes.includes(type);
    }
  };

  async function resizeFile(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = async () => {
        const buffer = Buffer.from(reader.result as ArrayBuffer);
        let image = await Image.load(buffer);

        if (image.width && image.width > 1500) {
          image = image.resize({ width: 1500 });
        }
        if (image.height && image.height > 1500) {
          image = image.resize({ height: 1500 });
        }

        let content = image.toBase64(file.type) as string;
        resolve(content);
      };
    });
  }

  const getUploadData = async (file: any) => {
    try {
      let content;
      if (getFileTypes(file.type) === 'JPEG' || getFileTypes(file.type) === 'PNG') {
        content = (await resizeFile(file)) as string;
      } else {
        content = (await getBase64(file)) as string;
      }
      const newFile: CustomFile = {
        fileName: file.name,
        fileContent: content,
        contentType: file.type,
      };

      if (fileList.length > 0) {
        let totalSize = 0;
        fileList.forEach((file) => {
          totalSize += file.fileContent.length;
        });
        totalSize += content.length;
        if (totalSize > 5 * 1024 * 1024) {
          setErrorMsg('Total file size exceeds 5MB.');
          return;
        }
      }
      setFileList((prev) => {
        const updatedList = [...prev, newFile];
        setValue(id, updatedList);
        if (callback) callback();

        return updatedList;
      });
    } catch (e) {
      setErrorMsg('Error in uploading.');
    }
  };

  const removeFile = (index: number) => {
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
    setValue(id, newFileList);
  };

  const getFileTypes = (type: string) => {
    switch (type) {
      case 'image/png':
        return 'PNG';
      case 'image/jpeg':
        return 'JPEG';
      case 'application/pdf':
        return 'PDF';
      case 'application/msword':
        return 'DOC';
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'DOCX';
      default:
        return type;
    }
  };

  const printFileTypes = () => {
    return (
      <div className="ant-upload-hint mt-3 mb-3 flex flex-row ">
        {fileType?.map((type: string, index: number) => (
          <span key={index} className="text-grey-400 text-xs flex items-center">
            <span>{getFileTypes(type)}</span>
            {fileType && index === fileType.length - 2 ? (
              <span className="mx-1">or</span>
            ) : fileType && index !== fileType.length - 1 ? (
              <span className="mr-1">,</span>
            ) : null}
          </span>
        ))}
        <span className="ml-1 text-[var(--grey-400)]">
          only - {maxFiles} files max (Total 5MB max)
        </span>
      </div>
    );
  };

  const handlePaste = async (event: ClipboardEvent) => {
    const items: any = event.clipboardData?.items;

    if (items && !isFileUploading) {
      setIsFileUploading(true);
      for (const item of items) {
        if (item.kind === 'file') {
          const file = item.getAsFile();
          if (file) {
            const allowedType = validateFileType(file, fileType);
            const fileNameRegex = /^[^\\/:*?"<>|,]+\.([a-zA-Z]{2,4})$/;
            if (!allowedType) {
              setIsFileUploading(false);
              setErrorMsg('Incompatible file type.');
              return;
            } else if (file.size && file.size > 3000000) {
              setIsFileUploading(false);
              setErrorMsg('File size exceeds 3MB.');
              return;
            } else if (fileList.length === maxFiles) {
              setIsFileUploading(false);
              setErrorMsg(`You can only upload a maximum of ${maxFiles} files.`);
              return;
            } else if (!fileNameRegex.test(file.name)) {
              setIsFileUploading(false);
              setErrorMsg('Invalid file name.');
              return;
            }
            if (validation) {
              let errorMessage = await validation(file);
              if (errorMessage) {
                setErrorMsg(errorMessage);
                setIsFileUploading(false);

                return;
              }
            }
            setErrorMsg('');
            await getUploadData(file);
          }
        }
      }
      setIsFileUploading(false);
    }
  };

  useEffect(() => {
    if (allowPaste) {
      const pasteHandler = (event: any) => handlePaste(event);

      window.addEventListener('paste', pasteHandler as EventListener);
      return () => {
        window.removeEventListener('paste', pasteHandler as EventListener);
      };
    }
  }, [fileList, isFileUploading]);

  return (
    <div className="h-full relative">
      <Controller
        name={id}
        control={control}
        rules={{ required: required }} // Add required validation rule
        render={({ field }) => (
          <Dragger
            {...props}
            {...field}
            fileList={fileList.map((file, index) => ({
              uid: index.toString(),
              name: file.fileName,
              status: 'done',
              url: file.fileContent,
            }))}
            showUploadList={false}
            style={{ backgroundColor: 'white', border: 'solid 1px black' }}
          >
            <>
              {fileList.length === 0 ? (
                <>
                  {allowPaste && (
                    <input
                      type="text"
                      className="absolute top-0 left-0 w-full h-full opacity-0  cursor-pointer"
                    />
                  )}
                  <div className="flex flex-row gap-20 justify-center items-center">
                    <div className="ant-upload-drag-icon">
                      <ImageUpload />
                    </div>
                    <div className="flex flex-col items-start gap-2">
                      <p className="text-sm">Ways to upload:</p>
                      <p className="text-sm font-bold">{`1. Drag and Drop your images here`}</p>
                      {allowPaste && (
                        <>
                          <p className="text-sm font-bold">
                            {`2. Copy and Paste your images here`}
                          </p>
                          <p className="text-xs text-grey-400">{`(Ctrl + v / Right click and paste)`}</p>
                          <p className="text-sm font-bold">3. Choose images from your file</p>
                        </>
                      )}
                      {!allowPaste && (
                        <p className="text-sm font-bold">2. Choose images from your file</p>
                      )}
                      <CommonButton
                        title="Choose File"
                        variant="primary"
                        type="button"
                        onClick={undefined}
                      />
                      {printFileTypes()}
                    </div>
                  </div>
                </>
              ) : (
                <div className="flex flex-wrap -mx-1 inline-flex text-center items-center w-full justify-center">
                  {(isLoading || isFileUploading) && (
                    <CircularProgress size={96} sx={{ color: '#6FB8C9' }} />
                  )}
                  {!(isLoading || isFileUploading) && (
                    <div className="w-full  flex flex-row justify-center">
                      {allowPaste && (
                        <input
                          type="text"
                          className="absolute top-0 left-0 w-full h-full opacity-0  cursor-pointer"
                        />
                      )}
                      {fileList.map((file, index) => (
                        <div key={index} className="relative flex p-1">
                          <div className="relative pr-2">
                            {getFileTypes(file.contentType) === 'JPEG' ||
                            getFileTypes(file.contentType) === 'PNG' ? (
                              <div>
                                <img
                                  src={`data:${file.contentType};base64,${file.fileContent}`}
                                  alt={file.fileName}
                                  className="max-w-[144px] max-h-[166px]"
                                />
                                <p className="text-sm">{file.fileName}</p>
                              </div>
                            ) : getFileTypes(file.contentType) === 'PDF' ? (
                              <div>
                                <iframe
                                  src={`${file.fileContent}`}
                                  width="400"
                                  height="500"
                                  className="max-w-full h-auto"
                                ></iframe>
                                <p className="text-sm">{file.fileName}</p>
                              </div>
                            ) : (
                              <a
                                href={`data:${file.contentType};base64,${file.fileContent}`}
                                download={file.fileName}
                              >
                                {file.fileName}
                              </a>
                            )}
                          </div>
                          <Delete
                            onClick={(e: any) => {
                              removeFile(index);
                              if (callback) callback();
                              e.stopPropagation();
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
              {errorMsg && errorMsg.length > 0 && (
                <div className="inline-flex text-center items-center">
                  <Warning className="d-flex" />
                  <p className="text-[var(--red)] text-base font-bold pl-1">
                    {errorMsg}
                    <span className="pointer-events-auto text-base underline text-[var(--blue-500)] pl-1">
                      {errorMsg === 'Error in uploading.' ? 'Try again' : 'Choose another'}
                    </span>
                  </p>
                </div>
              )}
            </>
          </Dragger>
        )}
      />
    </div>
  );
};

export default FileUpload;
