import { useEffect, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import DynamicForm from '@components/common/DynamicForm';
import { userFormGeneralItems } from './UserFormConfig';
import { useUpdateUserMutation } from '@api/userSlice';
import cloneDeep from 'lodash.clonedeep';
import { getSessionUserFromAccessToken } from '@utils/utils';
import { CommonButton } from '@components/common/CommonButton';

export default function UpdateUserComponent({ onModalClose }: { onModalClose: any }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [user, setUser] = useState<any>({
    email: '',
    role: [],
    resetPassword: 'If you wish to reset your password, please enter your current password first.',
  });
  const [updateUser] = useUpdateUserMutation();
  const [createFormItems, setFormItems] = useState(cloneDeep(userFormGeneralItems));

  useEffect(() => {
    const getUserData = async () => {
      const sessionUser = await getSessionUserFromAccessToken();
      setUser({ ...user, ...sessionUser });
    };

    getUserData();
  }, []);

  const formMethods = useForm({
    defaultValues: user,
    mode: 'all',
  });

  useEffect(() => {
    let formattedFormItems = cloneDeep(userFormGeneralItems);
    let currentPasswordField = formattedFormItems.find((item) => item.id === 'currentPassword');
    let newPasswordField = formattedFormItems.find((item) => item.id === 'newPassword');
    let confirmPasswordField = formattedFormItems.find((item) => item.id === 'confirmPassword');

    if (currentPasswordField) {
      currentPasswordField.rules.validate = {
        passwordRequired: () => {
          const formValues: any = formMethods.getValues();
          return (
            !(
              formValues.currentPassword &&
              (!formValues.newPassword || !formValues.confirmPassword)
            ) || 'Please enter a valid New Password and Confirm Password!'
          );
        },
      };
    }

    if (newPasswordField) {
      newPasswordField.rules.validate = {
        samePassword: () => {
          const formValues: any = formMethods.getValues();
          return (
            !(formValues.newPassword && formValues.newPassword === formValues.currentPassword) ||
            'New Password should not be the same as current password!'
          );
        },
        passwordStrength: () => {
          const formValues: any = formMethods.getValues();
          const newPassword = formValues.newPassword;
          const atLeastMinimumLength = new RegExp(/(?=.{8,})/).test(newPassword);
          const atLeastOneLowercaseLetter = new RegExp(/(?=.*?[a-z])/).test(newPassword);
          const atLeastOneUppercaseLetter = new RegExp(/(?=.*?[A-Z])/).test(newPassword);
          const atLeastOneNumber = new RegExp(/(?=.*?[0-9])/).test(newPassword);
          const atLeastOneSpecialChar = new RegExp(
            /(?=.*?[`~!@#$%^&*_()\-+={}[\]:;"',.?/<>])/,
          ).test(newPassword);

          return (
            !newPassword ||
            (atLeastMinimumLength &&
              atLeastOneLowercaseLetter &&
              atLeastOneUppercaseLetter &&
              atLeastOneNumber &&
              atLeastOneSpecialChar) ||
            'Invalid password strength'
          );
        },
      };
    }
    if (confirmPasswordField) {
      confirmPasswordField.rules.validate = {
        confirmPassword: () => {
          const formValues: any = formMethods.getValues();
          return (
            !formValues.newPassword ||
            formValues.newPassword === formValues.confirmPassword ||
            'Passwords should match!'
          );
        },
      };
    }
    setFormItems(formattedFormItems);
  }, [formMethods]);

  useEffect(() => {
    formMethods.reset(user);
  }, [user, formMethods]);

  const onSubmit = async (event: any) => {
    setIsSubmitting(true);
    const formValues: any = formMethods.getValues();
    let response: any;

    if (
      user.firstName !== formValues.firstName ||
      user.lastName !== formValues.lastName ||
      user.contactNumber !== formValues.contactNumber ||
      user.designation !== formValues.designation ||
      (formValues.currentPassword && formValues.newPassword && formValues.confirmPassword)
    ) {
      let params = {
        pathParameters: { user_id: user.userId },
        body: {
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          contactNumber: formValues.contactNumber,
          designation: formValues.designation,
          currentPassword: formValues.currentPassword,
          newPassword: formValues.newPassword,
          confirmPassword: formValues.confirmPassword,
        },
      };

      response = await updateUser(params);
    } else {
      console.log('No changes, skip update');
    }

    setIsSubmitting(false);

    if (!response?.error) {
      onModalClose();
    }
  };

  return (
    <div className="overflow-auto max-h-screen pb-20">
      <form onSubmit={formMethods.handleSubmit(onSubmit)} autoComplete="off">
        <div className="flex flex-col">
          <DynamicForm
            formFields={createFormItems}
            formMethods={formMethods}
            data={user}
          ></DynamicForm>
        </div>
        <div className="flex flex-col pt-4 text-left">
          <CommonButton
            className="ml-0 w-full flex justify-center"
            variant="primary"
            type="submit"
            title={isSubmitting ? 'Submitting...' : 'Submit'}
            isLoading={isSubmitting}
          />
        </div>
      </form>
    </div>
  );
}
