export const DEFAULT_LOGGED_IN_PAGE: any = {
  "default": '/admin/portfolios/monikers',
  "portfolios.proactivescamenablers.view": '/admin/portfolios/proactive',
  "pid": '/admin/portfolios/proactive'
};

export const TEAMS: any = [
    {id: "gasp", label: "GASP"},
    {id: "asc", label: "ASC"},
    {id: "pid", label: "PID"}
];