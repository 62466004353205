import { useState, ChangeEvent, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLoginTotpMutation } from '@api/sessionSlice';
import LoginHeader from '@components/common/LoginHeader';
import { CommonButton } from '@components/common/CommonButton';

const LoginTotpPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loginTotp] = useLoginTotpMutation();
  const [totp, setTotp] = useState<string[]>(Array(6).fill(''));
  const inputsRef = useRef<(HTMLInputElement | null)[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (elementIndex: number, event: ChangeEvent<HTMLInputElement>) => {
    let newTotp = [...totp];
    newTotp[elementIndex] = event.target.value;
    setTotp(newTotp);

    if (event.target.value.length === 1 && elementIndex < 5) {
      inputsRef.current[elementIndex + 1]?.focus();
    }

    if (newTotp.every((digit) => digit.length === 1)) {
      submit(newTotp.join(''));
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData('text/plain');
    const pastedDigits = pastedData.split('');

    if (pastedDigits.length === 6 && pastedDigits.every((digit) => /\d/.test(digit))) {
      setTotp(pastedDigits);
      inputsRef.current[5]?.focus();
      submit(pastedDigits.join(''));
    }
  };

  const handleKeyDown = (elementIndex: number, event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' && totp[elementIndex] === '') {
      inputsRef.current[elementIndex - 1]?.focus();
    }
  };

  const submit = async (totp: string) => {
    // Submit the TOTP
    setIsLoading(true);
    let params = {
      verificationCode: totp,
    };
    const response: any = await loginTotp(params);
    setIsLoading(false);

    if (!response.error) {
      console.log('TOTP Verified');
      let route = searchParams.get('route') || '';

      if (route) {
        route = `?route=${route}`;
      }
      return navigate(`/login${route}`);
    }
  };

  return (
    <div className="md:flex flex-col items-center justify-center min-h-screen bg-white md:bg-gradient-to-b md:from-blue-300 md:to-blue-400">
      <div className="flex flex-col w-full h-full md:w-1/2 xl:w-5/12 gap-2 bg-white py-16 px-10 md:px-16 rounded-lg">
        <LoginHeader />

        <h1 className="mb-4 ml-1 text-center">Login</h1>
        <div className="flex flex-col items-start pb-4">
          <p className="font-bold text-sm">Verification code</p>
          <p className="text-grey-400 text-sm">
            Get the 6-digit verification code from your authenticator
          </p>
        </div>
        <div className="flex justify-center gap-2.5 md:gap-4 pb-4">
          {totp.map((digit, index) => (
            <input
              key={index}
              type="text"
              name={`totp${index}`}
              value={digit}
              onPaste={handlePaste}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(index, event)}
              onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                handleKeyDown(index, event)
              }
              className="w-10 h-10 md:w-16 md:h-16 text-center border-grey-400 border-2 rounded-md"
              maxLength={1}
              pattern="\d*"
              ref={(ref) => (inputsRef.current[index] = ref)}
            />
          ))}
        </div>
        <CommonButton
          className="ml-0 w-full flex justify-center"
          variant="primary"
          type="submit"
          title={isLoading ? 'Logging in...' : 'Login'}
          onClick={() => submit(totp.join(''))}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default LoginTotpPage;
