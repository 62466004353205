import clsx from 'clsx';
import { ReactComponent as crossImage } from '@assets/icons/cross.svg';
import { forwardRef, ForwardedRef } from 'react';
import { Link } from 'react-router-dom';
import { CommonButton, IconButton } from '@components/common/CommonButton';
import { ReactComponent as Back } from '@assets/icons/back.svg';

interface ModalLayoutProps {
  title: string;
  handleCloseModal: any;
  children: any;
  alignment: string | 'center' | 'right';
  size: string | 'small' | 'medium' | 'large' | 'full';
  link?: string;
  linkText?: string;
  isNestedLayout?: boolean;
  handleCustomLink?: any;
  customLinkText?: string;
  overflowClass?: string;
  subTitle?: string;
  nestedLayourBackTitle?: string;
}

const ModalLayout = forwardRef(
  (
    {
      title,
      handleCloseModal,
      children,
      alignment,
      size,
      link,
      linkText,
      isNestedLayout,
      handleCustomLink,
      customLinkText,
      overflowClass = `overflow-auto`,
      subTitle = '',
      nestedLayourBackTitle = 'Back to Related Reports',
    }: ModalLayoutProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const CrossImage = crossImage;

    return (
      <div
        ref={ref}
        tabIndex={0}
        className={clsx(
          '',
          {
            'fixed inset-0 flex items-center justify-center': alignment === 'center',
            'flex flex-col w-[35%] absolute right-0 h-full bg-white': alignment === 'right',
          },
          overflowClass,
        )}
      >
        <div
          className={clsx(`p-8 ${overflowClass ? overflowClass : 'overflow-auto'}`, {
            'w-1/3 h-auto bg-white flex flex-col  max-h-[90vh]': size === 'small',
            'w-1/2 h-auto bg-white flex flex-col  max-h-[90vh]': size === 'medium',
            'w-5/6 h-auto bg-white flex flex-col  max-h-[90vh]': size === 'large',

            'w-1/2 h-auto bg-white flex flex-col  max-h-[90vh] pb-0': size === 'medium-form',
            'w-full bg-white ': size === 'full',
          })}
        >
          {isNestedLayout && (
            <>
              <div className="flex justify-between items-center mb-4">
                <div className="flex flex-row gap-1">
                  <Back></Back>
                  <CommonButton
                    isDisabled={false}
                    variant="text-blue"
                    title={nestedLayourBackTitle}
                    onClick={handleCloseModal}
                  />
                </div>
                <IconButton onClick={() => handleCloseModal()} icon="cross" isDisabled={false} />
              </div>
              <div className="flex flex-row justify-between">
                <h2 className="font-bold text-blue-500">{title}</h2>
                {link && linkText && (
                  <Link to={link} className="text-blue-400 font-bold" onClick={handleCloseModal}>
                    {linkText}
                  </Link>
                )}
              </div>
            </>
          )}
          {!isNestedLayout && (
            <>
              <div className="flex flex-row justify-between mb-4">
                <div className="flex flex-row gap-2 items-center">
                  <h2 className="font-bold text-black">{title}</h2>
                </div>
                {customLinkText ? (
                  <div
                    className="content-center text-blue-500 cursor-pointer"
                    onClick={handleCustomLink}
                  >
                    {customLinkText}
                  </div>
                ) : (
                  <IconButton onClick={() => handleCloseModal()} icon="cross" isDisabled={false} />
                )}
              </div>
              <div className="flex flex-row justify-between pb-4">
                <h1 className="font-bold text-lg pb-4 text-blue-500">{subTitle}</h1>
                {link && linkText && (
                  <Link to={link} className="text-blue-500 underline" onClick={handleCloseModal}>
                    {linkText}
                  </Link>
                )}
              </div>
            </>
          )}
          {children}
        </div>
      </div>
    );
  },
);

export default ModalLayout;
