import { Table, Pagination, ConfigProvider } from 'antd';
import { DebounceInput } from 'react-debounce-input';
import { AddReportButton, DownloadReportsButton } from '../Buttons';
import cloneDeep from 'lodash.clonedeep';
import getColumns from './getColumns';
import useSearch from '@components/common/useSearch';
import useTableParams from '@components/common/useTableParams';
import { useEffect, useRef, useState } from 'react';
import { CommonButton } from '@components/common/CommonButton';
import FilterModal from '@components/common/FilterModal';
import CheckReportsModal from '../modals/CheckReportsModal';
import { useForm } from 'react-hook-form';
import FilterAssets from '@components/common/FilterAssets';
import { reportFilterGeneralItems } from '../ReportFormConfig';
import AccessControl from '@components/common/AccessControl';
import { useGetReportInfoQuery } from '@api/reportsSlice';

export default function ReportTable({ platforms }: { platforms?: string }) {
  const { searchTerm, handleSearch } = useSearch();

  const defaultFilterValues = {
    report_status: [],
    scam_type: [],
    'created_date_from,created_date_to': [],
    created_date_from: '',
    created_date_to: '',
    classification: [],
  };
  const formMethods = useForm({
    mode: 'onChange',
  });

  const initialParams = {
    sort: {
      sortId: 'createdAt',
      sortOrder: 'desc' as const, // Update the sortOrder property to have a value of 'asc' as a string literal
    },
    pagination: {
      current: 1,
      pageSize: 50,
    },
    filters: defaultFilterValues,
  };

  const { tableParams, setTableParams, handlePageChange, handleTableChange } =
    useTableParams(initialParams);
  const [filterOpen, setFilterOpen] = useState(false);
  const [checkReportOpen, setCheckReportOpen] = useState(false);

  const handleFiltersChange = (filters: any) => {
    if (filters.classification) {
      filters.classification = filters.classification.join(',');
    }
    if (filters.scam_type) {
      filters.scam_type = filters.scam_type.join(',');
    }
    if (filters.created_date_from) {
      filters.created_date_from = Date.parse(filters.created_date_from);
    }
    if (filters.created_date_to) {
      filters.created_date_to = Date.parse(filters.created_date_to);
    }

    setTableParams({
      ...tableParams,
      pagination: { ...tableParams.pagination, current: 1 },
      filters,
    });
  };

  const [selectedRows, setSelectedRows] = useState<Array<any>>([]);

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setSelectedRows(selectedRows);
    },
  };

  const { refetch, data, isSuccess, isError, isLoading, isFetching } = useGetReportInfoQuery({
    params: {
      // platforms: platforms,
      search: searchTerm,
      sort_by: tableParams.sort.sortId,
      order_by: tableParams.sort.sortOrder,
      page: tableParams.pagination.current,
      size: tableParams.pagination.pageSize,
      filters: tableParams.filters,
    },
  });

  const columns = getColumns(refetch);
  const tableRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    tableRef.current?.scrollTo(0, 0);
  }, [data]);

  return (
    <>
      <AccessControl type="component" allowedPermissions={['reports']}>
        <div className="rounded-lg bg-white p-2 md:pt-0">
          <div className="flex items-center justify-between gap-2 py-3">
            <div className="flex flex-row gap-4 items-center">
              <div className="w-64">
                <DebounceInput
                  className="w-64 h-12 p-4 bg-grey-300 rounded-[8px] border-none text-grey-500 text-sm"
                  minLength={2}
                  debounceTimeout={300}
                  placeholder="Search for reports..."
                  onChange={handleSearch}
                />
              </div>
              <CommonButton
                isDisabled={false}
                variant="secondary"
                title={`Filters`}
                onClick={() => setFilterOpen(true)}
              />
            </div>

            <div className="flex flex-row gap-2 mr-4">
              <DownloadReportsButton />
              <AddReportButton refetch={() => setTimeout(refetch, 2000)} />
              {/* {
                <CommonButton
                  isDisabled={false}
                  variant="primary"
                  title={`Check Reports`}
                  onClick={() => setCheckReportOpen(true)}
                />
              } */}
              {/* <AssignButton
                selectedReports={selectedRows}
                showSelectedReports={true}
                callback={refetch}
                isDisabled={selectedRows.length <= 0}
              /> */}
            </div>
          </div>
          <FilterAssets
            defaultValues={defaultFilterValues}
            formMethods={formMethods}
            handleFiltersChange={handleFiltersChange}
          />
          <div className="max-h-[calc(100vh-280px)] overflow-auto" ref={tableRef}>
            <ConfigProvider
              theme={{
                components: {
                  Table: {
                    /* here is your component tokens */
                    headerBg: '#D0DEE0',
                  },
                },
              }}
            >
              <Table
                rowSelection={{ ...rowSelection }}
                dataSource={cloneDeep(data?.reports)}
                columns={columns}
                // rowKey="reportId"
                rowKey="_id"
                pagination={false}
                onChange={handleTableChange}
                loading={isLoading || isFetching}
                sticky
                scroll={{ x: 'max-content', y: 'max-content' }}
              />
            </ConfigProvider>
          </div>

          <Pagination
            className="pt-2 flex justify-end"
            defaultCurrent={1}
            current={tableParams.pagination.current}
            total={data?.totalNumberOfRecords}
            onChange={handlePageChange}
            showSizeChanger={true}
            defaultPageSize={50}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
          />
        </div>
        <CheckReportsModal
          isOpen={checkReportOpen}
          onModalClose={() => setCheckReportOpen(false)}
        />
        <FilterModal
          filterOpen={filterOpen}
          formItems={reportFilterGeneralItems}
          formMethods={formMethods}
          onClearFilters={() => formMethods.reset(defaultFilterValues)}
          onFilterClose={() => setFilterOpen(false)}
          onSubmitFilter={(filters: any) => {
            handleFiltersChange(filters);
          }}
        />
      </AccessControl>
    </>
  );
}
