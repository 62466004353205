import { ReportDetailedInterface, ScamEnablerInterface } from '@interface/ScamEnablerInterface';
import { apiSlice } from './apiSlice';
import { useDispatch } from 'react-redux';
import { showAlert } from '@store/alertSlice';
import { useEffect, useState } from 'react';
import { FormItemInterface } from '@interface/dynamicFormInterface';
import cloneDeep from 'lodash.clonedeep';
import { faceBookItems, scamItemsEnablersGeneralItems } from '@pages/reports/ReportFormConfig';
import { scamEnablerOutcomes } from '@interface/constant';
import axios from 'axios';
import { BACKEND_URL } from '@environment/AppEnvironment';
import { getFileNameFromContentDisposition } from '@utils/utils';
import { saveAs } from 'file-saver';
import { useGetReportDetailsByIdV2Query } from './reportsSlice';

// const defaultMonikerPlatforms = "Facebook,Shopee,Lazada,Carousell,Telegram,WeChat,Line,X,TikTok,Instagram,WhatsApp";
const defaultReportStatus = 'pendingReview,underReview,inProgress,pendingStakeholders,caseClosed';

export const scamEnablersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // getScamEnablers: builder.query({
    //   query: (request) => {
    //     return {
    //       url: '/v1/scamenablers',
    //       params: {
    //         page: request.params.page,
    //         size: request.params.size,
    //         search: request.params.search,
    //         sort_by: request.params.sort_by,
    //         order_by: request.params.order_by,
    //         platforms: request.params.platforms,
    //         report_status: request.params.filters?.report_status
    //           ? request.params.filters.report_status
    //           : defaultReportStatus,
    //         scam_type: request.params.filters?.scam_type,
    //         date_from: request.params.filters?.date_from,
    //         date_to: request.params.filters?.date_to,
    //         da1: request.params.filters?.da1,
    //         da2: request.params.filters?.da2,
    //       },
    //     };
    //   },
    //   transformResponse: (response: any) => {
    //     return response;
    //   },
    // }),

    // getScamEnablersById: builder.query({
    //   query: (params) => {
    //     return {
    //       url: `/v1/scamenabler/${params.id}`,
    //     };
    //   },
    //   transformResponse: (response: any) => {
    //     return response as ReportDetailedInterface;
    //   },
    // }),
    // getScamEnablersByIds: builder.query({
    //   query: (params) => {
    //     return {
    //       method: 'POST',
    //       body: params,
    //       url: `/v1/scamenablers`,
    //     };
    //   },
    //   transformResponse: (response: any) => {
    //     return response.scamEnablers;
    //   },
    // }),
    getScamEnablerComments: builder.query({
      query: (params) => {
        return {
          method: 'GET',
          url: `v1/scamenablers/comments/${params.scamenabler_id}`,
        };
      },
      transformResponse: (response: any) => {
        return response.comments;
      },
    }),
    createScamEnablerComment: builder.mutation({
      query: (params) => {
        return {
          method: 'POST',
          body: params,
          url: `v1/scamenablers/comments`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    updateScamEnablerCommentsRead: builder.mutation({
      query: (params) => {
        return {
          method: 'PUT',
          body: params,
          url: `v1/scamenablers/comments`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    // updateScamEnablerOutcome: builder.mutation({
    //   query: (params) => {
    //     return {
    //       method: 'PUT',
    //       body: params,
    //       url: `/v1/scamenabler/outcome`,
    //     };
    //   },
    //   transformResponse: (response: any) => {
    //     return response;
    //   },
    // }),
    // updateScamEnabler: builder.mutation({
    //   query: (params) => {
    //     return {
    //       method: 'PUT',
    //       body: params,
    //       url: `/v1/scamenabler`,
    //     };
    //   },
    //   transformResponse: (response: any) => {
    //     return response;
    //   },
    // }),

    //V2 ======================================================================================
    getScamEnablersV2: builder.query({
      query: (request) => {
        return {
          url: '/v2/scamenablers',
          params: {
            page: request.params.page,
            size: request.params.size,
            search: request.params.search,
            sort_by: request.params.sort_by,
            order_by: request.params.order_by,
            platforms: request.params.filters?.platforms,
            outcome: request.params.filters?.outcome,
            scam_type: request.params.filters?.scam_type,
            created_date_from: request.params.filters?.created_date_from,
            created_date_to: request.params.filters?.created_date_to,
            po_sent_date_from: request.params.filters?.po_sent_date_from,
            po_sent_date_to: request.params.filters?.po_sent_date_to,
            da1: request.params.filters?.da1,
            da2: request.params.filters?.da2,
            channel: request.params.filters?.channel,
            direction: request.params.filters?.direction,
          },
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    getScamEnablersByIdV2: builder.query({
      query: (params) => {
        return {
          url: `/v2/scamenabler/${params.id}`,
        };
      },
      transformResponse: (response: any) => {
        if (response?.scamEnabler?.findings?.monikerPreSubmitChecklist1?.checklist?.channel) {
          response.scamEnabler.channel =
            response?.scamEnabler?.findings?.monikerPreSubmitChecklist1?.checklist?.channel;
        }
        if (response?.scamEnabler?.findings?.monikerPreSubmitChecklist1?.checklist?.direction) {
          response.scamEnabler.direction =
            response?.scamEnabler?.findings?.monikerPreSubmitChecklist1?.checklist?.direction;
        }

        return response;
      },
    }),
    updateScamEnablerV2: builder.mutation({
      query: (params) => {
        return {
          method: 'PUT',
          body: params,
          url: `/v2/scamenablers`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    uploadScamEnablerAttachmentV2: builder.mutation({
      query: (params) => {
        return {
          method: 'PUT',
          body: params,
          url: `/v2/scamenabler/attachment`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    deleteScamEnablerAttachmentV2: builder.mutation({
      query: (params) => {
        return {
          method: 'DELETE',
          body: params,
          url: `/v2/scamenabler/attachment`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    assignUsersV2: builder.mutation({
      query: (params) => {
        return {
          method: 'PUT',
          body: params,
          url: `/v2/scamenablers/assignusers`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    updateAssessmentFindingsV2: builder.mutation({
      query: (params) => {
        return {
          method: 'PUT',
          body: params,
          url: `/v2/scamenablers/assessmentfindings`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    getScamEnablersExistV2: builder.mutation({
      query: (params) => {
        return {
          method: 'POST',
          body: params,
          url: `/v2/scamenablers/checkexist`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    // Proactive Scam Enablers ===============================================
    getProactiveScamEnablersV2: builder.query({
      query: (request) => {
        return {
          url: '/v2/proactivescamenablers',
          params: {
            page: request.params.page,
            size: request.params.size,
            search: request.params.search,
            sort_by: request.params.sort_by,
            order_by: request.params.order_by,
            // platforms: request.params.filters?.platforms,
            outcome: request.params.filters?.outcome,
            scam_type: request.params.filters?.scam_type,
            created_date_from: request.params.filters?.created_date_from,
            created_date_to: request.params.filters?.created_date_to,
            officer_assigned: request.params.filters?.officer_assigned,
          },
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    getProactiveScamEnablersByIdV2: builder.query({
      query: (params) => {
        return {
          url: `/v2/proactivescamenablers/${params.id}`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    getProactiveScamEnablersExistV2: builder.mutation({
      query: (params) => {
        return {
          method: 'POST',
          body: params,
          url: `/v2/proactivescamenablers/checkexist`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    createProactiveScamEnablersV2: builder.mutation({
      query: (params) => {
        return {
          method: 'POST',
          body: params,
          url: `/v2/proactivescamenablers `,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    updateProactiveScamEnablersV2: builder.mutation({
      query: (params) => {
        return {
          method: 'PUT',
          body: params,
          url: `/v2/proactivescamenablers `,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    assignProactiveScamEnablersV2: builder.mutation({
      query: (params) => {
        return {
          method: 'PUT',
          body: params,
          url: `/v2/proactivescamenablers/assignusers`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    uploadProactiveScamEnablersAttachmentV2: builder.mutation({
      query: (params) => {
        return {
          method: 'PUT',
          body: params,
          url: `/v2/proactivescamenablers/attachments`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    deleteProactiveScamEnablersAttachmentV2: builder.mutation({
      query: (params) => {
        return {
          method: 'DELETE',
          body: params,
          url: `/v2/proactivescamenablers/attachments`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
  }),
});

// export const useScamEnablerUpdate = () => {
//   const [updateScamEnabler] = useUpdateScamEnablerMutation();
//   const dispatch = useDispatch();

//   const update = async (scamEnablerId: string, closedAt: number) => {
//     const response: any = await updateScamEnabler({
//       scamEnablerId,
//       closedAt,
//     });

//     if (!response.error) {
//       dispatch(
//         showAlert({
//           type: 'success',
//           message: `Update Successful`,
//         }),
//       );
//     }
//     return response;
//   };

//   return update;
// };

// export const useScamEnablerUpdateOutcome = () => {
//   const [updateScamEnablerOutcome] = useUpdateScamEnablerOutcomeMutation();
//   const dispatch = useDispatch();

//   const update = async (scamEnablerId: string, outcome: string) => {
//     const response: any = await updateScamEnablerOutcome({
//       scamEnablerId,
//       outcome,
//     });

//     if (!response.error) {
//       dispatch(
//         showAlert({
//           type: 'success',
//           message: `Update Successful`,
//         }),
//       );
//     }
//     return response;
//   };

//   return update;
// };

export const useScamEnablers = (selectedReport: string, userId: string, platform: string) => {
  const [relatedScamEnablerData, setRelatedScamEnablerData] = useState({});
  const [relatedScamEnablerFields, setRelatedScamEnablerFields] = useState<FormItemInterface[]>([]);

  const { data, error, isLoading } = useGetReportDetailsByIdV2Query(
    {
      id: selectedReport,
    },
    {
      skip: selectedReport === '',
    },
  );

  const processReport = (moniker: ScamEnablerInterface) => {
    let formDetails = [];
    switch (moniker.platform) {
      case 'Facebook':
        formDetails.push(
          ...cloneDeep(faceBookItems.concat(cloneDeep(scamItemsEnablersGeneralItems))),
        );
        break;
      default:
        formDetails.push(...cloneDeep(scamItemsEnablersGeneralItems));
    }

    const monikerOutcome = {
      label: 'Outcome of Moniker',
      type: 'labelEditDropdown',
      extras: {
        dropdownData: scamEnablerOutcomes,
      },
      id: 'outcome',
    };

    formDetails.splice(formDetails.length - 1, 0, monikerOutcome);

    setRelatedScamEnablerFields(formDetails);
  };

  useEffect(() => {
    if (error) {
      console.error('An error occurred:', error);
    }

    if (!isLoading && data) {
      //search data.scamEnablers that match userId and platform
      let relatedScamEnablers = data.scamEnablers.find(
        (enabler: any) => enabler.userId === userId && enabler.platform === platform,
      );
      if (relatedScamEnablers) {
        setRelatedScamEnablerData(relatedScamEnablers);
        processReport(relatedScamEnablers);
      }
      console.log('Data received:', data);
    }
  }, [data, error, isLoading]);

  return { relatedScamEnablerData, relatedScamEnablerFields, data, error, isLoading };
};

export interface assignUserParameters {
  scamEnablerId: string;
  userId: string;
  role: string;
}
export const useScamEnablerAssign = () => {
  const [assignReport] = useAssignUsersV2Mutation();
  const dispatch = useDispatch();

  const assign = async (assignUserParams: Array<assignUserParameters>) => {
    let response: any = await assignReport(assignUserParams);
    if (!response.error) {
      dispatch(
        showAlert({
          type: 'success',
          message: `Scam Enabler(s) assigned successfully to ${
            assignUserParams[0].userId
          } as the ${assignUserParams[0].role.toUpperCase()}`,
        }),
      );
    }
    return response;
  };

  return assign;
};

export const useDownloadScamEnablerCSV = () => {
  const dispatch = useDispatch();

  const download = async (filters: any) => {
    try {
      console.log('filters', filters);
      let url = `${BACKEND_URL}/v2/scamenablers/csv`;

      Object.keys(filters).forEach((key, index) => {
        url += (index ? '&' : '?') + `${key}=${encodeURIComponent(filters[key])}`;
      });

      const response = await axios.get(url, {
        responseType: 'blob', // Tell axios to get response data as Blob
        withCredentials: true, // Include credentials
      });

      const contentDisposition = response.headers['content-disposition'];
      const fileName = getFileNameFromContentDisposition(contentDisposition);
      saveAs(response.data, fileName);
      dispatch(
        showAlert({
          type: 'success',
          message: `Statistics downloaded successfully. Open the file from your downloads folder.`,
        }),
      );
    } catch (error: any) {
      console.error('Error downloading file:', error);
      dispatch(
        showAlert({
          type: 'error',
          message: `An error occurred: ${error.error_description} - ${error.trace_id}`,
        }),
      );
      return error;
    }
  };
  return download;
};

export const useDownloadMonikerStatistics = () => {
  const dispatch = useDispatch();

  const download = async (filters: any) => {
    try {
      console.log('filters', filters);
      let url = `${BACKEND_URL}/v2/statistics/monikers`;

      Object.keys(filters).forEach((key, index) => {
        url += (index ? '&' : '?') + `${key}=${encodeURIComponent(filters[key])}`;
      });

      const response = await axios.get(url, {
        responseType: 'blob', // Tell axios to get response data as Blob
        withCredentials: true, // Include credentials
      });

      const contentDisposition = response.headers['content-disposition'];
      const fileName = getFileNameFromContentDisposition(contentDisposition);
      saveAs(response.data, fileName);
      dispatch(
        showAlert({
          type: 'success',
          message: `Statistics downloaded successfully. Open the file from your downloads folder.`,
        }),
      );
    } catch (error: any) {
      console.error('Error downloading file:', error);
      dispatch(
        showAlert({
          type: 'error',
          message: `An error occurred: ${error.error_description} - ${error.trace_id}`,
        }),
      );
      return error;
    }
  };
  return download;
};

export const useDownloadProactiveScamEnablerCSV = () => {
  const dispatch = useDispatch();

  const download = async (filters: any) => {
    try {
      let url = `${BACKEND_URL}/v2/proactivescamenablers/csv`;

      Object.keys(filters).forEach((key, index) => {
        url += (index ? '&' : '?') + `${key}=${encodeURIComponent(filters[key])}`;
      });

      const response = await axios.get(url, {
        responseType: 'blob', // Tell axios to get response data as Blob
        withCredentials: true, // Include credentials
      });

      const contentDisposition = response.headers['content-disposition'];
      const fileName = getFileNameFromContentDisposition(contentDisposition);
      saveAs(response.data, fileName);
      dispatch(
        showAlert({
          type: 'success',
          message: `Statistics downloaded successfully. Open the file from your downloads folder.`,
        }),
      );
    } catch (error: any) {
      dispatch(
        showAlert({
          type: 'error',
          message: `An error occurred: ${error.error_description} - ${error.trace_id}`,
        }),
      );
      return error;
    }
  };
  return download;
};

export const useDownloadProactiveScamEnablerFileForSubmission = () => {
  const dispatch = useDispatch();

  const download = async (proactiveScamEnablerIds: any) => {
    try {
      let url = `${BACKEND_URL}/v2/proactivescamenablers/fileforsubmission?proactiveScamEnablerIds=${proactiveScamEnablerIds}`;

      const response = await axios.get(url, {
        responseType: 'blob', // Tell axios to get response data as Blob
        withCredentials: true, // Include credentials
      });

      const contentDisposition = response.headers['content-disposition'];
      const fileName = getFileNameFromContentDisposition(contentDisposition);
      saveAs(response.data, fileName);
      dispatch(
        showAlert({
          type: 'success',
          message: `Statistics downloaded successfully. Open the file from your downloads folder.`,
        }),
      );
    } catch (error: any) {
      dispatch(
        showAlert({
          type: 'error',
          message: `An error occurred: ${error.error_description} - ${error.trace_id}`,
        }),
      );
      return error;
    }
  };
  return download;
};

export const useDownloadBankSmsCSV = () => {
  const dispatch = useDispatch();

  const download = async (filters: any) => {
    try {
      let url = `${BACKEND_URL}/v2/financialinstitute/downloadsms`;

      Object.keys(filters).forEach((key, index) => {
        url += (index ? '&' : '?') + `${key}=${encodeURIComponent(filters[key])}`;
      });

      const response = await axios.get(url, {
        responseType: 'blob', // Tell axios to get response data as Blob
        withCredentials: true, // Include credentials
      });

      const contentDisposition = response.headers['content-disposition'];
      const fileName = getFileNameFromContentDisposition(contentDisposition);
      saveAs(response.data, fileName);
      dispatch(
        showAlert({
          type: 'success',
          message: `SMS downloaded successfully. Open the file from your downloads folder.`,
        }),
      );
    } catch (error: any) {
      dispatch(
        showAlert({
          type: 'error',
          message: `An error occurred: ${error.error_description} - ${error.trace_id}`,
        }),
      );
      return error;
    }
  };
  return download;
};

export const {
  // useGetScamEnablersQuery,
  // useGetScamEnablersByIdQuery,
  // useGetScamEnablersByIdsQuery,
  useGetScamEnablerCommentsQuery,
  useCreateScamEnablerCommentMutation,
  useUpdateScamEnablerCommentsReadMutation,
  // useUpdateScamEnablerOutcomeMutation,
  // useUpdateScamEnablerMutation,
  useGetScamEnablersV2Query,
  useGetScamEnablersByIdV2Query,
  useUpdateScamEnablerV2Mutation,
  useAssignUsersV2Mutation,
  useUpdateAssessmentFindingsV2Mutation,
  useUploadScamEnablerAttachmentV2Mutation,
  useDeleteScamEnablerAttachmentV2Mutation,
  useGetScamEnablersExistV2Mutation,
  useGetProactiveScamEnablersV2Query,
  useGetProactiveScamEnablersByIdV2Query,
  useGetProactiveScamEnablersExistV2Mutation,
  useUpdateProactiveScamEnablersV2Mutation,
  useAssignProactiveScamEnablersV2Mutation,
  useUploadProactiveScamEnablersAttachmentV2Mutation,
  useDeleteProactiveScamEnablersAttachmentV2Mutation,
  useCreateProactiveScamEnablersV2Mutation,
} = scamEnablersApiSlice;
