import Chip from '@mui/material/Chip';
import dayjs from 'dayjs';
import { ReactComponent as Cross } from '@assets/icons/cross_circle.svg';

const FilterAssets = ({
  defaultValues,
  formMethods,
  handleFiltersChange,
}: {
  defaultValues: any;
  formMethods: any;
  handleFiltersChange: any;
}) => {
  type FilterLabel =
    | 'created_date_from,created_date_to'
    | 'po_sent_date_from,po_sent_date_to'
    | 'da1'
    | 'da2'
    | 'officer_assigned'
    | 'dutyOfficer'
    | string;

  const clearDateFilters = (filterLabel: FilterLabel, formMethods: any) => {
    formMethods.setValue(filterLabel, []);
    const [fromLabel, toLabel] = filterLabel.split(',');
    formMethods.setValue(fromLabel, '');
    formMethods.setValue(toLabel, '');
  };

  const handleClearFilter = (filterLabel?: FilterLabel, filterValue?: string) => {
    if (!filterLabel) {
      formMethods.reset(defaultValues);
      handleFiltersChange(formMethods.getValues());
      return;
    }

    const currentValue = formMethods.getValues()[filterLabel];
    if (
      [
        'created_date_from,created_date_to',
        'po_sent_date_from,po_sent_date_to',
        'da1',
        'da2',
        'officer_assigned',
        'dutyOfficer',
      ].includes(filterLabel)
    ) {
      if (filterLabel.includes(',')) {
        clearDateFilters(filterLabel, formMethods);
      } else {
        formMethods.setValue(filterLabel, '');
      }
    } else if (currentValue) {
      const newValue = currentValue.filter((value: string) => value !== filterValue);
      formMethods.setValue(filterLabel, newValue);
    }

    handleFiltersChange(formMethods.getValues());
  };

  const processValueFunctions = {
    da1: (value: any) => `DA1: ${value.label}`,
    da2: (value: any) => `DA2: ${value.label}`,
    scam_type: (value: any) => value,
    classification: (value: any) => value,
    report_status: (value: any) => value,
    'created_date_from,created_date_to': (value: any) =>
      `${dayjs(value[0]).format('DD/MM/YYYY')} - ${dayjs(value[1]).format('DD/MM/YYYY')}`,
    'po_sent_date_from,po_sent_date_to': (value: any) =>
      `${dayjs(value[0]).format('DD/MM/YYYY')} - ${dayjs(value[1]).format('DD/MM/YYYY')}`,
    platform: (value: any) => value,
    platforms: (value: any) => value,
    outcome: (value: any) => value,
    channel: (value: any) => value,
    direction: (value: any) => value,
    officer_assigned: (value: any) => `Officer Assigned: ${value.label}`,
    dutyOfficer: (value: any) => `Duty Officer: ${value.label}`,
  };

  const showFilterChips = () => {
    const allValues = formMethods.getValues();
    let chips = Object.entries(processValueFunctions).flatMap(([label, processValue]) => {
      const value = allValues[label];

      if (!value) return [];

      //for dropdownMulti
      if (Array.isArray(value) && !label.includes(',')) {
        return value.map((item) => ({ label, value: processValue(item) }));
      }
      //for date ranges
      else if (label.includes(',')) {
        if (value.length > 0 && value[0] !== '' && value[1] !== '') {
          return [{ label, value: processValue(value) }];
        }
      } else if (value.length > 0 || value !== '') {
        return [{ label, value: processValue(value) }];
      }
      return [];
    });

    return chips;
  };

  const chips = showFilterChips();

  return (
    <div className={`flex flex-row gap-2 flex-wrap mr-auto ${chips.length > 0 ? 'pb-3' : ''}`}>
      {chips.length > 0 && <div className="content-center">Active Filters:</div>}
      {chips.map((chip: any, index: number) => (
        <Chip
          key={index}
          label={chip.value}
          sx={{
            backgroundColor: 'var(--blue-200)',
            color: 'var(--grey-500)',
            fontWeight: '700',
            fontSize: '12px',
          }}
          deleteIcon={<Cross />}
          onDelete={() => handleClearFilter(chip.label, chip.value)}
        />
      ))}
      {chips.length > 0 && (
        <div
          className="content-center text-[var(--blue-500)] cursor-pointer"
          onClick={() => handleClearFilter()}
        >
          Reset Filters
        </div>
      )}
    </div>
  );
};

export default FilterAssets;
