import { Modal } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ModalLayout from '@components/layouts/ModalLayout';
import { CommonButton } from '@components/common/CommonButton';
import {
  useCreateProactiveScamEnablersV2Mutation,
  useGetProactiveScamEnablersExistV2Mutation,
} from '@api/scamEnablerSlice';
import DynamicForm from '@components/common/DynamicForm';

import { debounce } from 'lodash';
import { createProactiveItems } from '../ProactiveMonikersFormConfig';
import { extractUserIdFromUrl } from '@utils/utils';

const CreateProactiveMonikerModal = ({
  isOpen,
  handleClose,
  callback,
}: {
  isOpen: boolean;
  handleClose: () => void;
  callback: () => void;
}) => {
  const formMethods = useForm({
    defaultValues: {
      attachmentFileNames: [' '],
      scammerContactNumber: ['+65'],
      url: 'https://www.facebook.com/profile.php?id=',
    },
    mode: 'onChange',
  });

  const [parentExtras, setParentExtras] = useState({} as any);

  const urlField = formMethods.watch('url');
  const [checkUserId] = useGetProactiveScamEnablersExistV2Mutation();
  const [createProactiveScamEnabler] = useCreateProactiveScamEnablersV2Mutation();
  const [isLoading, setIsLoading] = useState(false);

  // Function to update loading and verification state
  const updateUrlState = (isLoading: boolean, isVerified: boolean) => {
    setParentExtras({
      url: {
        isLoading,
        isVerified,
      },
    });
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const data: any = formMethods.getValues();

      let userId = data.url.replace('https://www.facebook.com/profile.php?id=', '');
      data.scamEnablerUserId = userId;
      data.platform = 'Facebook';
      if (data.numberOfAds || data.numberOfAds === 0) data.numberOfAds = parseInt(data.numberOfAds);
      else if (!data.numberOfAds) {
        delete data.numberOfAds;
      }
      if (data.facebookCreatedAt)
        data.facebookCreatedAt = new Date(data.facebookCreatedAt).setHours(0, 0, 0, 0);
      if (data.attachments && data.attachments.length > 0) {
        data.attachments.forEach((attachment: any) => {
          let splitFileName = attachment.fileName.split('.');
          attachment.fileName = `${data.scamEnablerUserId}.${splitFileName[splitFileName.length - 1]}`;
        });
      }
      if (data.attachmentFileNames && data.attachmentFileNames.length > 0) {
        data.attachmentFileNames = data.attachmentFileNames
          .map((row: string) => (row ? row.trim() : ''))
          .filter((row: string) => row !== '');

        if (data.attachmentFileNames.length === 0) {
          delete data.attachmentFileNames;
        }
      }
      if (data.scammerContactNumber && data.scammerContactNumber.length > 0) {
        data.scammerContactNumber = data.scammerContactNumber
          .map((row: string) => (row ? row.trim() : '+65'))
          .filter((row: string) => row !== '+65');

        if (data.scammerContactNumber.length === 0) {
          delete data.scammerContactNumber;
        }
      }
      let response: any = await createProactiveScamEnabler([data]);
      if (!response.error) {
        if (callback) callback();
        handleClose();
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const debouncedCheckURL = debounce(async () => {
      if (urlField) {
        const { isValidUrl, userId } = extractUserIdFromUrl(urlField, 'Facebook');
        if (isValidUrl && userId.length >= 2) {
          updateUrlState(true, false); // Start loading
          try {
            let response = await checkUserId({
              proactiveScamEnablers: [
                {
                  scamEnablerUserId: userId,
                  platform: 'Facebook',
                },
              ],
            }).unwrap();

            const user = response?.result.find((item: any) => {
              return item.scamEnablerUserId === userId;
            });
            if (user && user.exists === false) {
              formMethods.clearErrors('url');
              updateUrlState(false, true); // Success
              return; // Exit early on success
            } else {
              formMethods.setError('url', {
                type: 'manual',
                message: 'User already exists',
              });
            }
          } catch (error) {
            console.error('Error retrieving profile', error);
            formMethods.setError('url', {
              type: 'manual',
              message: 'Error retrieving profile',
            });
          }
          updateUrlState(false, false); // End loading with error or user exists
        }
      }
    }, 500); // 500ms debounce time
    debouncedCheckURL();

    return () => {
      debouncedCheckURL.cancel(); // Cancel any pending executions on component unmount
    };
  }, [urlField, checkUserId, formMethods]);

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <ModalLayout
        title={'Add Report - Manual Entry'}
        handleCloseModal={handleClose}
        alignment="center"
        size="large"
        overflowClass="none"
      >
        <>
          <p className="text-sm text-grey-400 -mt-8">
            All Fields are required unless marked as optional
          </p>
          <div className="overflow-auto max-h-[75vh] mt-2">
            <p className="text-grey-400 pb-4 -mt-4">
              All fields are required unless marked as optional
            </p>
            <form>
              <DynamicForm
                formFields={createProactiveItems}
                formMethods={formMethods}
                parentExtras={parentExtras}
              />
            </form>
          </div>
          <div className="flex justify-end gap-4 pt-4">
            <CommonButton
              isDisabled={false}
              variant="text"
              title={`Cancel`}
              onClick={() => {
                handleClose();
              }}
            />
            <CommonButton
              isDisabled={!formMethods.formState.isValid}
              variant="primary"
              title={`Add`}
              onClick={handleSubmit}
              isLoading={isLoading}
            />
          </div>
        </>
      </ModalLayout>
    </Modal>
  );
};

export default CreateProactiveMonikerModal;
