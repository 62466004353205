import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { useFieldArray } from 'react-hook-form';
import { useRef, useEffect, useState } from 'react';
import { FormItemInterface } from '@interface/dynamicFormInterface';
import TableRowInput from './TableRowInput';
import cloneDeep from 'lodash.clonedeep';
import UploadExcelModal from '@pages/reports/modals/UploadExcelModal';
import { CommonButton } from './CommonButton';

const TableInput = ({
  id,
  index,
  formMethods,
  formFields,
  websiteItemState,
  setWebsiteItemState
}: {
  id?: string;
  index: string;
  formMethods: any;
  formFields: any;
  websiteItemState: Array<Array<FormItemInterface>>;
  setWebsiteItemState: any;
}) => {
  const [showUploadExcelModal, setShowUploadExcelModal] = useState(false);
  const { fields, append, remove } = useFieldArray({
    control: formMethods.control,
    name: index,
  });

  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);

  const onHandleDelete = (index: number | null) => {
    if (index != null) {
      let newWebsiteFormState = cloneDeep(websiteItemState);
      newWebsiteFormState.splice(index, 1);
      newWebsiteFormState.forEach((website, i) => {
        website.forEach((item, j) => {
          let id = item.id.split('.')[1];
          item.id = `${index}[${i}].${id}`;
        });
      });
      setWebsiteItemState(newWebsiteFormState);
      remove(index);
    }
  };

  const appendArray = async () => {
    await append({ url: '', category: '', brand: '', attachments: [] });
  };

  useEffect(() => {
    sectionRefs.current = sectionRefs.current.slice(0, fields.length);

    if (fields.length === 0) {
      appendArray();
    }
  }, [fields]);

  return (
    <TableContainer className="pt-1">
      <div className="flex flex-row justify-between pb-1">
        <label className="w-1/3 font-bold mr-4 pb-1 pl-1">{'Website URL'}</label>
        <CommonButton
          title="Upload Excel List"
          variant={'text-blue'}
          onClick={() => setShowUploadExcelModal(true)}
          type="button"
        />
      </div>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: 'var(--grey-300)' }}>
            <TableCell sx={{ fontWeight: 700, width: '5%' }}>No.</TableCell>
            <TableCell sx={{ fontWeight: 700, width: '50%' }}>Website URL</TableCell>
            <TableCell sx={{ fontWeight: 700, width: '40%' }}>Screenshots (Optional)</TableCell>
            {fields.length > 0 && <TableCell sx={{ width: '5%' }}></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((field, fieldIndex) => (
            <TableRow
              key={fieldIndex}
              sx={{ backgroundColor: fieldIndex % 2 === 0 ? 'white' : 'var(--grey-100)' }}
              ref={(el) => (sectionRefs.current[fieldIndex] = el)}
            >
              <TableRowInput
                key={field.id}
                id={`${index}[${fieldIndex}]`}
                index={fieldIndex}
                field={formFields[0].extras?.tableData[0]}
                length={fields.length}
                handleDelete={onHandleDelete}
                formMethods={formMethods}
              />
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <CommonButton
        className="pt-2 pb-3"
        title="Add Another Row"
        variant={'text-blue'}
        onClick={async () => {
          await append({ url: '', category: '', brand: '', attachments: [] });
        }}
        type="button"
      />
      <UploadExcelModal
        id={index}
        isOpen={showUploadExcelModal}
        onClose={() => setShowUploadExcelModal(false)}
        append={appendArray}
        formMethods={formMethods}
      />
    </TableContainer>
  );
};

export default TableInput;
