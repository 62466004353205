import {
  platform,
  proactiveProfileType,
  proactiveScamEnablerOutcomes,
  scamType,
} from '@interface/constant';
import { FormItemInterface } from '@interface/dynamicFormInterface';
import { getFileMimeTypes } from '@utils/utils';

export const proactiveMonikerfilterGeneralItems: Array<FormItemInterface> = [
  {
    label: 'Scam Type',
    type: 'dropdownMulti',
    id: 'scam_type',
    placeholder: 'Please Select',
    extras: { dropdownData: scamType },
    alignment: 'row',
  },
  {
    label: 'Date Added',
    type: 'calendarRange',
    id: 'created_date_from,created_date_to',
    placeholder: 'dd/mm/yyyy',
    alignment: 'row',
  },
  {
    label: 'Officer Assigned',
    type: 'dropdownUser',
    id: 'officer_assigned',
    extras: { userGroup: 'proactiveScamEnablers' },
    alignment: 'row',
  },
  {
    label: 'Outcome of Moniker',
    type: 'dropdownMulti',
    id: 'outcome',
    extras: { dropdownData: proactiveScamEnablerOutcomes },
    placeholder: 'Plese select',
    alignment: 'row',
  },
];

export const overviewEditItems: Array<FormItemInterface> = [
  {
    label: 'Officer Assigned',
    id: 'assigned',
    type: 'dropdownUser',
    extras: { userGroup: 'proactiveScamEnablers' },
  },
  {
    label: 'Outcome',
    type: 'dropdown',
    id: 'outcome',
    extras: {
      dropdownData: proactiveScamEnablerOutcomes,
    },
  },
  {
    label: 'Date Submitted',
    id: 'submittedAt',
    type: 'dateTime',
  },
  {
    label: 'Date of Reply',
    id: 'closedAt',
    type: 'dateTime',
  },
  {
    label: 'Reference No.',
    id: 'referenceNo',
    type: 'textInput',
  },
  {
    label: 'Review TAT',
    id: 'reviewTAT',
    type: 'label',
  },
  {
    label: 'Return TAT',
    id: 'returnTAT',
    type: 'label',
  },
];

export const overviewItems: Array<FormItemInterface> = [
  {
    label: 'Officer Assigned',
    id: 'assigned',
    type: 'labelEditDropdownUser',
    extras: { updateAPI: 'assignScamEnablerV2', userGroup: 'scamEnablers' },
  },
  {
    label: 'Outcome',
    type: 'labelEditDropdown',
    id: 'outcome',
    extras: {
      type: 'proactive',
      updateAPI: 'updateScamEnablerV2',
      dropdownData: proactiveScamEnablerOutcomes,
    },
  },
  {
    label: 'Date Submitted',
    id: 'submittedAt',
    type: 'labelEditDateTime',
  },
  {
    label: 'Date of Reply',
    id: 'closedAt',
    type: 'labelEditDateTime',
  },
  {
    label: 'Reference No.',
    id: 'referenceNo',
    type: 'label',
  },
  {
    label: 'Review TAT',
    id: 'reviewTAT',
    type: 'label',
  },
  {
    label: 'Return TAT',
    id: 'returnTAT',
    type: 'label',
  },
];

export const monikerItems: Array<FormItemInterface> = [
  {
    label: 'User ID',
    id: 'scamEnablerUserId',
    type: 'label',
  },
  {
    label: 'URL',
    id: 'url',
    type: 'label',
    extras: {
      isLoading: false,
      isVerified: false,
    },
  },
  {
    label: 'Profile Name',
    id: 'profilename',
    type: 'label',
  },
  {
    label: 'Facebook Creation Date',
    id: 'facebookCreatedAt',
    type: 'date',
    extras: {
      format: "DD MMMM YYYY HH:ss"
    }
  },
  {
    label: 'Screenshot File Name',
    id: 'attachmentFileNames',
    type: 'label',
  },
  {
    label: 'Screenshot(s)',
    type: 'screenshot',
    id: 'proactiveScamEnablerId',
    extras: {
      attachmentPath: '/v2/proactivescamenablers/attachments/',
      updateAPI: 'uploadProactiveScamEnablerAttachmentV2',
      allowPaste: true,
    },
  },
  {
    label: 'Scam Type',
    id: 'scamType',
    type: 'label',
  },
  {
    label: 'Profile Type',
    id: 'profileType',
    type: 'label',
  },
  {
    label: 'No of Ads',
    id: 'numberOfAds',
    type: 'label',
  },
  {
    label: 'Phone No',
    id: 'scammerContactNumber',
    type: 'label',
  },
  {
    label: 'Remarks',
    id: 'remarks',
    type: 'label',
  },
];

export const monikerEditItems: Array<FormItemInterface> = [
  {
    label: 'User ID',
    id: 'scamEnablerUserId',
    type: 'label',
    rules: {
      pattern: {},
      required: 'Please enter a userID',
    },
  },
  {
    label: 'URL',
    id: 'url',
    type: 'textInput',
    placeholder: 'e.g. https://www.facebook.com/profile.php?id=111111111111111',
    rules: {
      pattern: {},
      required: 'Please enter a url',
    },
  },
  {
    label: 'Profile Name',
    id: 'profilename',
    type: 'textInput',
    rules: {
      pattern: {},
      required: 'Please enter a Profile Name',
    },
  },
  {
    label: 'Facebook Creation Date',
    id: 'facebookCreatedAt',
    type: 'dateTime',
    extras: {
      showTime: false,
      format: ["DD MMMM YYYY", "DD MMMM"]
    },
  },
  {
    label: 'Screenshot File Name',
    id: 'attachmentFileNames',
    type: 'multiTextInput',
    extras: {
      tooltip: {
        content:
          'This field is manually editable and does not automatically update with the file name of the screenshot you upload',
      },
    },
  },
  {
    label: 'Screenshot(s)',
    id: 'attachment',
    type: 'screenshot',
    extras: {
      attachmentPath: '/v2/proactivescamenablers/attachments/',
      updateAPI: 'uploadProactiveScamEnablerAttachmentV2',
    },
  },
  {
    label: 'Scam Type',
    id: 'scamType',
    type: 'dropdown',
    extras: {
      dropdownData: scamType,
    },
  },
  {
    label: 'Profile Type',
    id: 'profileType',
    type: 'dropdown',
    extras: {
      dropdownData: proactiveProfileType,
    },
  },
  {
    label: 'No of Ads',
    id: 'numberOfAds',
    type: 'textInput',
    rules: {
      pattern: {
        value: /^[0-9]*$/,
        message: 'Please enter a valid number',
      },
    },
    extras: {
      textInputType: 'number',
    },
  },
  {
    label: 'Phone No.',
    id: 'scammerContactNumber',
    type: 'multiTelephoneInput',
  },
  {
    label: 'Remarks',
    id: 'remarks',
    type: 'textInput',
    extras: {
      rows: 4,
    },
  },
];

export const createProactiveItems: Array<FormItemInterface> = [
  {
    label: 'Profile Name',
    type: 'textInput',
    id: 'profilename',
    alignment: 'column',
    rules: {
      pattern: {},
      required: 'Please enter a Profile Name',
    },
  },
  {
    label: 'URL',
    type: 'textInput',
    id: 'url',
    alignment: 'column',
    placeholder: 'e.g. https://www.facebook.com/profile.php?id=111111111111111',
    rules: {
      pattern: {},
      required: 'Please enter a URL',
      validate: {
        startsWithFacebookProfileUrl: (value: string) =>
          (value.startsWith('https://www.facebook.com/profile.php?id=') && value.length > 40) ||
          'URL must start with https://www.facebook.com/profile.php?id=',
      },
    },
  },
  {
    label: 'Facebook Creation Date',
    id: 'facebookCreatedAt',
    alignment: 'column',
    optional: true,
    type: 'dateTime',
    extras: {
      showTime: false,
      format: ["DD MMMM YYYY", "DD MMMM"]
    },
  },
  {
    label: 'Screenshot File Name',
    id: 'attachmentFileNames',
    alignment: 'column',
    type: 'multiTextInput',
    optional: true,
    extras: {
      tooltip: {
        content:
          'This field is manually editable and does not automatically update with the file name of the screenshot you upload',
      },
    },
  },
  // {
  //   label: 'Platform',
  //   type: 'dropdown',
  //   id: 'platform',
  //   alignment: 'column',
  //   extras: {
  //     dropdownData: platform,
  //   },
  //   rules: {
  //     required: 'Please select an option',
  //   },
  // },
  {
    label: 'Scam Type',
    type: 'dropdown',
    id: 'scamType',
    alignment: 'column',
    extras: {
      dropdownData: scamType,
    },
    optional: true,
  },
  {
    label: 'Profile Type',
    type: 'dropdown',
    id: 'profileType',
    alignment: 'column',
    optional: true,
    extras: {
      dropdownData: proactiveProfileType,
    },
  },
  {
    label: 'No of Ads',
    type: 'textInput',
    id: 'numberOfAds',
    alignment: 'column',
    optional: true,
    rules: {
      pattern: {
        value: /^[0-9]+$/,
        message: 'Please enter a valid number',
      },
    },
    extras: {
      textInputType: 'number',
    },
  },
  {
    label: 'Phone Number',
    type: 'multiTelephoneInput',
    id: 'scammerContactNumber',
    alignment: 'column',
    optional: true,
  },
  {
    label: 'Attachment(s)',
    type: 'fileUpload',
    alignment: 'column',
    id: 'attachments',
    extras: {
      fileType: getFileMimeTypes(['image', 'pdf', 'word']),
      maxFiles: 3,
      allowPaste: true,
    },
    optional: true,
  },
  {
    label: 'Remarks',
    id: 'remarks',
    alignment: 'column',
    type: 'textInput',
    extras: {
      rows: 4,
    },
    optional: true,
  },
];

//=======================================================================================================
//UPDATE PROACTIVE MONIKER MODAL

export const validColumns = [
  {
    title: 'Row',
    key: 'index',
    render: (text: string, record: any, index: number) => index + 1,
  },
  {
    title: 'Moniker ID',
    dataIndex: 'scamEnablerUserId',
    key: 'scamEnablerUserId',
    width: '90%',
  },
];

export const invalidColumns = [
  {
    title: 'Row',
    key: 'index',
    render: (text: string, record: any, index: number) => index + 1,
  },
  {
    title: 'Moniker ID',
    dataIndex: 'scamEnablerUserId',
    key: 'scamEnablerUserId',
    width: '45%',
  },
  {
    title: 'Reason',
    dataIndex: 'errorMessage',
    key: 'errorMessage',
    width: '45%',
  },
];
export const monikerIdConfig = [
  // {
  //   label: 'Platform',
  //   type: 'dropdown',
  //   id: 'platform',
  //   alignment: 'column',
  //   extras: {
  //     dropdownData: platform,
  //   },
  //   rules: {
  //     required: 'Please select an option',
  //   },
  // },
  {
    label: 'Input the moniker IDs below',
    helperText: 'Fill in one user ID/phone no./full URL(FB and IG only) per line',
    type: 'textInput',
    id: 'monikerId',
    alignment: 'column',
    rules: {
      required: 'Please enter a Moniker ID',
    },
    extras: {
      multiline: true,
      rows: 10,
    },
  },
];

export const updateProactiveItems: Array<FormItemInterface> = [
  {
    label: 'Outcome',
    type: 'dropdown',
    id: 'outcome',
    alignment: 'column',
    extras: {
      dropdownData: proactiveScamEnablerOutcomes,
    },
  },
  // {
  //   label: 'Facebook Creation Date',
  //   id: 'facebookCreatedAt',
  //   alignment: 'column',
  //   type: 'calendar',
  // },
  // {
  //   label: 'Attachment File Names',
  //   id: 'attachmentFileNames',
  //   type: 'multiTextInput',
  //   alignment: 'column',
  //   extras: {
  //     tooltip: {
  //       content:
  //         'This field is manually editable and does not automatically update with the file name of the screenshot you upload',
  //     },
  //   },
  // },
  {
    label: 'Date Submitted',
    id: 'submittedAt',
    type: 'dateTime',
    alignment: 'column',
  },
  {
    label: 'Date of Reply',
    id: 'closedAt',
    type: 'dateTime',
    alignment: 'column',
  },
  // {
  //   label: 'Scam Type',
  //   type: 'dropdown',
  //   id: 'scamType',
  //   alignment: 'column',
  //   extras: {
  //     dropdownData: scamType,
  //   },
  // },
  // {
  //   label: 'Profile Type',
  //   type: 'dropdown',
  //   id: 'profileType',
  //   alignment: 'column',
  //   extras: {
  //     dropdownData: proactiveProfileType,
  //   },
  // },
  {
    label: 'Reference No.',
    id: 'referenceNo',
    alignment: 'column',
    type: 'textInput',
  },
  // {
  //   label: 'No of Ads',
  //   type: 'textInput',
  //   id: 'numberOfAds',
  //   alignment: 'column',
  //   rules: {
  //     pattern: {
  //       value: /^[0-9]+$/,
  //       message: 'Please enter a valid number',
  //     },
  //   },
  //   extras: {
  //     textInputType: 'number',
  //   },
  // },
  // {
  //   label: 'Phone Number',
  //   type: 'multiTelephoneInput',
  //   id: 'scammerContactNumber',
  //   alignment: 'column',
  // },
];
