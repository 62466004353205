import { useEffect, useRef, useState } from 'react';
import {
  Table,
  Pagination,
  TableProps,
  GetProp,
  TablePaginationConfig,
  ConfigProvider,
} from 'antd';
import cloneDeep from 'lodash.clonedeep';
import { useGetProductionOrderQueueV2Query } from '@api/productionOrderSlice';
import { convertUnixToSingaporeTime } from '@utils/utils';
import { DeleteSingleButton } from '../../reports/Buttons';
import DirectionsQueueHeaderGroup from './DirectionsQueueHeaderGroup'; // handleSelectSingleMoniker, // handleSelectSingleGeneratePO,
import { CommonButton } from '@components/common/CommonButton';
import ProductionOrderMonikerModal from '../modals/ProductionOrderMonikerModal';
import { GenerateProductionOrderButton } from '@pages/portfolios/Buttons';
import { MonikerOutcomeStatus } from '@components/common/StatusComponent';
import AccessControl from '@components/common/AccessControl';

interface sortBy {
  sortId: string;
  sortOrder: 'asc' | 'desc';
}

interface TableParams {
  pagination: TablePaginationConfig;
  sort: sortBy;
  filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

export default function TableComponent() {
  const [searchTerm, setSearchTerm] = useState('');
  const [tableParams, setTableParams] = useState<TableParams>({
    sort: {
      sortId: 'createdAt',
      sortOrder: 'asc',
    },
    pagination: {
      current: 1,
      pageSize: 50,
    },
  });
  const [selectedRows, setSelectedRows] = useState<Array<any>>([]);
  const [selectedScamEnablerIds, setSelectedScamEnablerIds] = useState<Array<string>>([]);
  const [selectedPOQueueIds, setSelectedPOQueueIds] = useState<Array<string>>([]);
  const [monikerModalSelectedId, setMonikerModalSelectedId] = useState('');
  const [monikerModalOpen, setMonikerModalOpen] = useState(false);
  const [poModalOpen, setPOModalOpen] = useState(false);

  const column = [
    {
      title: 'Moniker ID',
      dataIndex: 'scamEnablerUserId',
      key: 'scamEnablerUserId',
      width: '20%',
      sorter: true,
      render: (text: string, record: any, index: number) => (
        <div className="overflow-hidden">
          <CommonButton
            isDisabled={false}
            variant="underline-text"
            title={text}
            className="text-left"
            onClick={() => {
              setMonikerModalOpen(true);
              setMonikerModalSelectedId(record.poQueueId);
            }}
          />
        </div>
      ),
    },
    {
      title: 'Platform',
      dataIndex: 'platform',
      sorter: true,
      key: 'platform',
      width: '15%',
    },
    {
      title: 'Direction',
      key: 'direction',
      width: '15%',
      render: (text: string, record: any, index: number) => (
        <>
          {record.channel !== 'OCHA' && <p className="text-green">Community Guideline</p>}
          {record.channel === 'OCHA' && <p className="text-pink">{record.direction}</p>}
        </>
      ),
    },
    {
      title: 'Scam Type',
      dataIndex: 'scamType',
      sorter: true,
      width: '15%',
      key: 'scamType',
    },
    {
      title: 'Date Sent to Queue',
      dataIndex: 'createdAt',
      sorter: true,
      width: '15%',
      key: 'createdAt',
      render: (text: string, record: any, index: number) =>
        convertUnixToSingaporeTime(record.createdAt),
    },
    {
      title: 'Outcome of Moniker',
      dataIndex: 'outcome',
      sorter: true,
      width: '15%',
      key: 'outcome',
      render: (text: string, record: any, index: number) => (
        <MonikerOutcomeStatus outcome={record.outcome} />
      ),
    },
    {
      key: 'delete',
      width: '4%',
      render: (text: string, record: any, index: number) => (
        <DeleteSingleButton poQueueId={record.poQueueId} callback={refetch} />
      ),
    },
    {
      key: 'generate',
      width: '4%',
      render: (text: string, record: any, index: number) => (
        <GenerateProductionOrderButton scamEnablerData={[record]} callback={refetch} type="icon" />
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      const selectedScamEnablerIds = selectedRows.map((previewPOData: any) => {
        return previewPOData.scamEnablerUserId;
      });
      const selectedPOQueueIds = selectedRows.map((previewPOData: any) => {
        return previewPOData.poQueueId;
      });

      setSelectedScamEnablerIds(selectedScamEnablerIds);
      setSelectedPOQueueIds(selectedPOQueueIds);

      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const { refetch, data, isLoading, isFetching } = useGetProductionOrderQueueV2Query({
    params: {
      search: searchTerm,
      sort_by: tableParams.sort.sortId,
      order_by: tableParams.sort.sortOrder,
      page: tableParams.pagination.current,
      size: tableParams.pagination.pageSize,
      filters: tableParams.filters,
    },
  });

  useEffect(() => {
    refetch();
  }, []);

  const handlePageChange = (page: number, pageSize: number) => {
    setTableParams({
      ...tableParams,

      pagination: {
        current: page,
        pageSize: pageSize,
      },
    });
  };

  const handleTableChange: TableProps['onChange'] = (pagination, filters, sorter: any) => {
    setTableParams({
      ...tableParams,
      sort: {
        sortId: sorter.field ? sorter.field : '',
        sortOrder: sorter.order === 'ascend' ? 'asc' : 'desc',
      },
    });
  };
  const tableRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    tableRef.current?.scrollTo(0, 0);
  }, [data]);

  return (
    <>
      <AccessControl
        type="component"
        allowedPermissions={['directionsQueue.productionorders.view']}
      >
        <div className="rounded-lg bg-gray-50 p-2 md:pt-0 overflow-y-auto">
          <DirectionsQueueHeaderGroup
            selectedRows={selectedRows}
            selectedScamEnablerIds={selectedScamEnablerIds}
            selectedPOQueueIds={selectedPOQueueIds}
            refetch={refetch}
            setTableParams={setTableParams}
            tableParams={tableParams}
            setSearchTerm={setSearchTerm}
            view="monikerView"
          />
          <div className="max-h-[calc(100vh-250px)] overflow-auto" ref={tableRef}>
            <ConfigProvider
              theme={{
                components: {
                  Table: {
                    /* here is your component tokens */
                    headerBg: '#D0DEE0',
                  },
                },
              }}
            >
              <Table
                rowSelection={{
                  ...rowSelection,
                }}
                dataSource={cloneDeep(data?.poQueues)}
                columns={column}
                rowKey="poQueueId"
                pagination={false}
                onChange={handleTableChange}
                loading={isLoading || isFetching}
                sticky
              />
            </ConfigProvider>
          </div>

          <Pagination
            className="pt-2 flex justify-end"
            defaultCurrent={1}
            current={tableParams.pagination.current}
            total={data?.totalNumberOfRecords}
            onChange={handlePageChange}
            showSizeChanger={true}
            defaultPageSize={50}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
          />
        </div>
        <ProductionOrderMonikerModal
          isOpen={monikerModalOpen}
          onClose={() => {
            setMonikerModalOpen(false);
            // refetch();
          }}
          poQueueId={monikerModalSelectedId}
        />
      </AccessControl>
    </>
  );
}
