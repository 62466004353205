import { useEffect, useMemo, useState } from 'react';
import DynamicForm from '@components/common/DynamicForm';
import { overviewFormItemsGeneral, overviewInformationItemsGeneral } from '../PaynowFormConfig';
import { useForm } from 'react-hook-form';
import { useUpdatePaynowMutation } from '@api/paynowSlice';
import { showAlert } from '@store/alertSlice';
import { useDispatch } from 'react-redux';
import cloneDeep from 'lodash.clonedeep';
import { IconButton } from '@components/common/CommonButton';
import ActivityLog from '@pages/portfolios/monikers/MonikersDetailedPage/ActivityLog/ActivityLog';

export function PaynowDetailsOverview({ data, refetch }: { data: any; refetch: any }) {
  const [isActivityLogModalOpen, setIsActivityLogModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [updatePaynow] = useUpdatePaynowMutation();

  const dispatch = useDispatch();

  const defaultValues = useMemo(() => {
    if (data) {
      let newData = cloneDeep(data);
      return newData;
    }
  }, [data]);

  const formMethods = useForm({
    defaultValues,
    mode: 'onChange',
  });

  useEffect(() => {
    if (data) {
      formMethods.reset(defaultValues);
    }
  }, [data, defaultValues, formMethods]);

  return (
    <div>
      <div className="flex flex-row justify-between align-middle">
        <div className="w-100 flex flex-row items-center align-middle gap-4">
          <h1 className="font-bold text-lg text-blue-500">Overview</h1>
          {
            <>
              <IconButton
                isDisabled={false}
                icon="activity_log"
                type="button"
                onClick={() => setIsActivityLogModalOpen(true)}
                isLoading={false}
              />
              {data?.accountNumber && isActivityLogModalOpen && (
                <ActivityLog
                  isOpen={isActivityLogModalOpen}
                  handleClose={() => setIsActivityLogModalOpen(false)}
                  id={data.account?.accountNum}
                />
              )}
            </>
          }
        </div>
      </div>

      <div className="max-h-[75vh] pb-4 overflow-auto mt-5">
        {data && (
          <>
            <DynamicForm
              formFields={overviewInformationItemsGeneral}
              data={data}
              isEditable={false}
            />
          </>
        )}
        {!data && (
          <DynamicForm
            formFields={overviewInformationItemsGeneral}
            isLoading={true}
            isEditable={false}
          />
        )}
      </div>
    </div>
  );
}
